const translations = {
  'pt-br': {
    common: {
      search: 'Buscar',
      from: 'De',
      to: 'Até',
      cancel: 'Cancelar',
      save: 'Salvar',
      loading: 'Carregando...',
      view_all: 'Ver todas'
    },
    labels: {
      vulnerability_reports: 'Relatórios de Vulnerabilidade',
      // ... outras traduções
    },
    report_status: {
      new: 'Novo',
      under_review: 'Em Análise',
      // ... outras traduções
    },
    report_service: {
      'Bug Bounty': 'Bug Bounty',
      'Monitoring': 'Monitoramento',
      'Pentest': 'Pentest'
    },
    login: {
      title: 'Acessar sua conta',
      email_placeholder: 'Digite seu email',
      password_placeholder: 'Digite sua senha',
      forgot_password: 'Esqueceu sua senha?',
      submit: 'Acessar',
      no_account: 'Não tem uma conta?',
      contact_us: 'Contate-nos',
      loading: 'Aguarde...'
    },
    home: {
      welcome: 'Bem-vindo, {{userName}}!',
      help: 'Como podemos te ajudar hoje?',
      solutions: 'Soluções',
      applications: 'Aplicações',
      addApplication: 'Adicionar Aplicação',
      service: 'Serviço',
      type: 'Tipo',
      status: 'Status',
      lastUpdate: 'Última atualização',
      services: {
        applications: {
          name: 'Aplicações',
          description: 'Gerencie suas aplicações, adicione novas e monitore o status'
        },
        monitoring: {
          name: 'Monitoramento de Aplicações',
          description: 'Monitoramento contínuo de vulnerabilidades'
        },
        pentest: {
          name: 'Pentest',
          description: 'Testes de penetração manuais e automatizados'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas por vulnerabilidades'
        }
      }
    },
    navbar: {
      solutions: {
        title: 'Soluções',
        applications: {
          name: 'Aplicações',
          description: 'Gerencie suas aplicações, adicione novas e monitore o status'
        },
        reports: {
          name: 'Relatórios',
          description: 'Visualize e gerencie relatórios de vulnerabilidades'
        },
        monitoring: {
          name: 'Monitoramento de Aplicações',
          description: 'Monitoramento contínuo de vulnerabilidades'
        },
        codeScanning: {
          name: 'Code Scanning',
          description: 'Análise estática para identificação de vulnerabilidades no código fonte'
        },
        cloudSecurity: {
          name: 'Cloud Security',
          description: 'Proteção e monitoramento de ambientes em nuvem'
        },
        pentest: {
          name: 'Pentest',
          description: 'Teste de penetração em aplicações web, mobile e APIs'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas por vulnerabilidades detectadas'
        },
        active_solution: 'SOLUÇÃO ATIVA',
        not_active: 'NÃO ATIVADA'
      },
      profile: 'Perfil',
      settings: 'Configurações',
      logout: 'Sair'
    },
    monitoring: {
      dashboard: 'Dashboard',
      reports: 'Relatórios',
      insights: 'Insights',
      monitoringDashboard: 'Dashboard de Monitoramento',
      monitoringInsights: 'Insights de Monitoramento',
      monitoredApplications: 'Aplicações Monitoradas',
      preventedAttacks: 'Ataques Prevenidos',
      reportsInRetest: 'Relatórios em Reteste',
      newReports: 'Novos Relatórios',
      inAnalysis: 'Em Análise',
      closed: 'Fechados',
      impact_distribution: 'Distribuição por Impacto',
      total: 'Total',
      top_vulnerabilities: 'Top Vulnerabilidades',
      loading: 'Carregando...',
      error_loading: 'Erro ao carregar dados',
      critical: 'Crítico',
      high: 'Alto',
      medium: 'Médio',
      low: 'Baixo',
      filters: {
        impact_level: 'Nível de Impacto',
        status: 'Status',
        category: 'Categoria',
        application: 'Aplicação',
        select_application: 'Selecione uma aplicação',
        application_placeholder: 'Selecione uma aplicação',
        no_applications: 'Nenhuma aplicação encontrada',
        all: 'Todos',
        type: 'Tipo',
        date: {
          from: 'De',
          to: 'Até',
          search: 'Buscar'
        }
      },
      table: {
        report_name: 'Nome do Relatório',
        application: 'Aplicação',
        date: 'Data',
        type: 'Tipo',
        status: 'Status',
        impact: 'Impacto'
      },
      impact: {
        critical: 'Crítico',
        high: 'Alto',
        medium: 'Médio',
        low: 'Baixo'
      },
      status: {
        new: 'Novo',
        under_review: 'Em Análise',
        confirmed: 'Confirmado',
        awaiting_fix: 'Aguardando Correção',
        in_mitigation: 'Em Mitigação',
        awaiting_retest: 'Aguardando Reteste',
        retest_done: 'Reteste Concluído',
        fixed: 'Corrigido',
        closed: 'Fechado'
      },
      category: {
        broken_access_control: 'Controle de Acesso Quebrado',
        cryptographic_failures: 'Falhas Criptográficas',
        injection: 'Injeção',
        xss: 'Cross-Site Scripting (XSS)',
        insecure_design: 'Design Inseguro',
        security_misconfiguration: 'Configuração Incorreta de Segurança',
        vulnerable_outdated_components: 'Componentes Vulneráveis e Desatualizados',
        identification_authentication_failures: 'Falhas de Identificação e Autenticação',
        software_data_integrity_failures: 'Falhas de Integridade de Software e Dados',
        security_logging_monitoring_failures: 'Falhas de Registro e Monitoramento',
        ssrf: 'Server-Side Request Forgery (SSRF)',
        other: 'Outros'
      }
    },
    reports: {
      title: 'Relatórios de Vulnerabilidade',
      filters: {
        date: {
          from: 'De',
          to: 'Até',
          search: 'Buscar'
        },
        impact_level: {
          label: 'Nível de Impacto',
          all: 'Todos',
          critical: 'Crítico',
          high: 'Alto',
          medium: 'Médio',
          low: 'Baixo'
        },
        status: {
          label: 'Status',
          all: 'Todos',
          new: 'Novo',
          under_review: 'Em Análise',
          confirmed: 'Confirmado',
          awaiting_fix: 'Aguardando Correção',
          in_mitigation: 'Em Mitigação',
          awaiting_retest: 'Aguardando Reteste',
          retest_done: 'Reteste Concluído',
          fixed: 'Corrigido',
          closed: 'Fechado'
        },
        category: {
          label: 'Categoria',
          all: 'Todos',
          broken_access_control: 'Controle de Acesso Quebrado',
          cryptographic_failures: 'Falhas Criptográficas',
          injection: 'Injeção',
          xss: 'Cross-Site Scripting (XSS)',
          insecure_design: 'Design Inseguro',
          security_misconfiguration: 'Configuração Incorreta de Segurança',
          vulnerable_outdated_components: 'Componentes Vulneráveis e Desatualizados',
          identification_authentication_failures: 'Falhas de Identificação e Autenticação',
          software_data_integrity_failures: 'Falhas de Integridade de Software e Dados',
          security_logging_monitoring_failures: 'Falhas de Registro e Monitoramento',
          ssrf: 'Server-Side Request Forgery (SSRF)',
          other: 'Outros'
        },
        service: {
          monitoring: 'Monitoramento',
          pentest: 'Pentest',
          redteam: 'Red Team',
          vulnerability: 'Vulnerabilidade'
        }
      },
      table: {
        date: 'Data',
        report_name: 'Nome do Relatório',
        type: 'Tipo',
        status: 'Status',
        impact: 'Impacto',
        service: 'Serviço'
      }
    },
    company_select: {
      title: 'Selecionar Organização',
      subtitle: 'Escolha a organização que você deseja acessar',
      company_id: 'ID da organização',
      management_console: 'Console de gerenciamento',
      programmatic_access: 'Acesso programático',
      manage_account: 'Gerenciar Organização'
    },
    report_details: {
      vulnerability_details: 'Detalhes da Vulnerabilidade',
      reproduction_steps: 'Passos para Reprodução',
      manage_report: 'Gerenciar Relatório',
      movements: 'Movimentações',
      report_date: 'Data do Relatório',
      category: 'Categoria',
      application: 'Aplicação',
      application_name: 'Nome da Aplicação',
      application_host: 'Host da Aplicação',
      identifier: 'Identificador',
      status: 'Status',
      responsible: 'Responsável',
      criticality: 'Criticidade',
      select_responsible: 'Selecione um responsável',
      select_status: 'Selecione um status',
      add_note: 'Adicione uma anotação...',
      save_changes: 'Salvar Alterações',
      status_changed: 'Status alterado para',
      responsible_changed: 'Responsável alterado para',
      by: 'por',
      close: 'Fechar',
      movements_timeline: {
        status_change: 'Status alterado para',
        responsible_change: 'Responsável alterado para',
        by: 'por',
        note: 'Anotação'
      },
      solutions: 'Soluções',
      no_solutions: 'Nenhuma solução registrada'
    },
    applications: {
      add: 'Adicionar Aplicação',
      modal: {
        title: 'Nova Aplicação'
      },
      form: {
        host: 'Host',
        hostPlaceholder: 'Digite o host da aplicação (ex: https://exemplo.com)',
        applicationType: 'Tipo de Aplicação',
        operationType: 'Tipo de Operação'
      },
      types: {
        web: 'Aplicação Web',
        mobile: 'Aplicação Mobile',
        api: 'API'
      },
      operations: {
        blackbox: 'Black Box',
        greybox: 'Grey Box',
        whitebox: 'White Box'
      },
      tooltips: {
        blackBox: 'Teste sem acesso ao código fonte, simulando um ataque externo',
        greyBox: 'Teste com acesso parcial ao código fonte e documentação',
        whiteBox: 'Teste com acesso completo ao código fonte e documentação'
      },
      table: {
        application: 'Aplicação',
        host: 'Host',
        operation: 'Operação',
        monitoring: 'Monitoramento'
      },
      status: {
        active: 'Ativo',
        inactive: 'Inativo',
        monitoring: 'Monitorando'
      },
      menu: {
        applications: 'Aplicações'
      },
      connect_github: 'Conectar GitHub',
      github_connected: 'GitHub Conectado',
      github_integration: 'Integração com GitHub',
      repository: 'Repositório',
      branch: 'Branch',
      generate_pat: 'Gerar Token de Acesso Pessoal',
      pat_instructions: 'Gere um Fine-Grained PAT com as seguintes permissões:',
      generate_pat_link: 'Gerar novo token no GitHub',
      github_token: 'Token do GitHub',
      enter_github_token: 'Cole seu token aqui',
      github_repository: 'Repositório do GitHub',
      repository_format_help: 'Digite no formato: dono/repositório (exemplo: usuario/projeto)',
      repository_format_error: 'Formato inválido. Use: dono/repositório',
      token_invalid: 'Token inválido ou sem permissões necessárias',
      repository_not_found: 'Repositório não encontrado. Verifique se o nome está correto e se você tem acesso.',
      required_permissions: 'Permissões necessárias',
      permissions_help: 'Todas as permissões devem ser configuradas como "Read"',
      token_missing_permissions: 'O token não possui todas as permissões necessárias. Por favor, verifique se todas as permissões foram concedidas.',
      permissions: {
        contents: 'Para acessar código fonte e arquivos',
        metadata: 'Para informações básicas do repositório',
        pull_requests: 'Para monitorar mudanças via PRs',
        code_scanning: 'Para alertas de análise de código',
        dependabot: 'Para alertas de vulnerabilidades',
        secret_scanning: 'Para detecção de secrets expostos',
        workflows: 'Para análise de CI/CD (aceita Read ou Write)'
      },
      connection_error: 'Erro ao conectar com GitHub. Verifique o token e as permissões.',
      repository_access_error: 'Token não tem permissão de acesso ao repositório',
      permissions_instructions: 'No GitHub, ao criar o Fine-Grained PAT, selecione as seguintes permissões:',
      token_insufficient_permission: 'O token precisa ter pelo menos permissão de leitura no repositório',
      test_access: 'Testar Acesso ao GitHub',
      testing_access: 'Testando Acesso...',
      test_success: 'Todos os testes de acesso foram concluídos com sucesso!',
      test_error: 'Erro ao testar acesso ao repositório'
    },
    menu: {
      applications: 'Aplicações'
    },
    profile: {
      title: 'Perfil',
      name: 'Nome',
      email: 'E-mail',
      company: 'Organização',
      role: 'Cargo',
      created_at: 'Data de Cadastro'
    },
    module_management: {
      title: 'Gerenciamento de Módulos',
      description: 'Gerencie os módulos disponíveis para o cliente. Aqui você pode ativar ou desativar os módulos que a empresa terá acesso. Os módulos desativados não estarão disponíveis para nenhum usuário da organização do cliente.',
      nav_description: 'Gerencie os módulos disponíveis para os clientes',
      applications: {
        name: 'Aplicações',
        description: 'Gerencie suas aplicações web, mobile e APIs'
      },
      monitoring: {
        name: 'Monitoramento',
        description: 'Monitoramento contínuo de vulnerabilidades'
      }
    },
    totp: {
      title: 'Configuração de Autenticação em Duas Etapas',
      description: 'Digite o código de 6 dígitos do seu aplicativo autenticador',
      description_setup: 'Escaneie o QR Code com seu aplicativo autenticador (Google Authenticator ou Microsoft Authenticator) e digite o código gerado',
      code_placeholder: 'Digite o código',
      verify_button: 'Verificar',
      verifying: 'Verificando...',
      invalid_code_length: 'O código deve ter 6 dígitos',
      error_verify: 'Erro ao verificar código. Tente novamente.',
      error_setup: 'Erro ao configurar autenticação em duas etapas',
      success: 'Autenticação em duas etapas concluída com sucesso',
      scan_qr: 'Escaneie este QR Code com seu aplicativo autenticador',
      after_scan: 'Após escanear, digite o código que aparecer no aplicativo',
      setup_required: 'Configure a autenticação em duas etapas para continuar',
      authenticator_apps: 'Aplicativos recomendados: Google Authenticator, Microsoft Authenticator',
      title_info: 'A autenticação em duas etapas adiciona uma camada extra de segurança à sua conta. Além da senha, você precisará de um código gerado por um aplicativo autenticador.'
    }
  },
  'en': {
    common: {
      search: 'Search',
      from: 'From',
      to: 'To',
      cancel: 'Cancel',
      save: 'Save',
      loading: 'Loading...',
      view_all: 'View all'
    },
    labels: {
      vulnerability_reports: 'Vulnerability Reports',
      // ... outras traduções
    },
    report_status: {
      new: 'New',
      under_review: 'Under Review',
      // ... outras traduções
    },
    report_service: {
      'Bug Bounty': 'Bug Bounty',
      'Monitoring': 'Monitoring',
      'Pentest': 'Pentest'
    },
    login: {
      title: 'Access your account',
      email_placeholder: 'Enter your email',
      password_placeholder: 'Enter your password',
      forgot_password: 'Forgot your password?',
      submit: 'Access',
      no_account: "Don't have an account?",
      contact_us: 'Contact us',
      loading: 'Please wait...'
    },
    home: {
      welcome: 'Welcome, {{userName}}!',
      help: 'How can we assist you today?',
      solutions: 'Solutions',
      applications: 'Applications',
      addApplication: 'Add Application',
      service: 'Service',
      type: 'Category',
      status: 'Status',
      lastUpdate: 'Last update',
      services: {
        applications: {
          name: 'Applications',
          description: 'Manage your applications, add new ones, and monitor status'
        },
        monitoring: {
          name: 'Monitoring',
          description: 'Continuous vulnerability monitoring'
        },
        pentest: {
          name: 'Pentest',
          description: 'Manual and automated penetration testing'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Vulnerability rewards program'
        }
      }
    },
    navbar: {
      solutions: {
        title: 'Active Solutions',
        applications: {
          name: 'Applications',
          description: 'Manage your applications, add new ones, and monitor status'
        },
        reports: {
          name: 'Reports',
          description: 'View and manage vulnerability reports'
        },
        monitoring: {
          name: 'Application Monitoring',
          description: 'Continuous monitoring of vulnerabilities'
        },
        codeScanning: {
          name: 'Code Scanning',
          description: 'Análise estática automatizada de código fonte'
        },
        cloudSecurity: {
          name: 'Cloud Security',
          description: 'Protection and monitoring of cloud environments'
        },
        pentest: {
          name: 'Pentest',
          description: 'Penetration testing for web and mobile applications'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Belohnungsprogramm'
        },
        active_solution: 'ACTIVE SOLUTION',
        not_active: 'NOT ACTIVATED'
      },
      profile: 'Profile',
      settings: 'Settings',
      logout: 'Logout'
    },
    monitoring: {
      dashboard: 'Dashboard',
      reports: 'Reports',
      insights: 'Insights',
      monitoringDashboard: 'Monitoring Dashboard',
      monitoringInsights: 'Monitoring Insights',
      monitoredApplications: 'Monitored Applications',
      preventedAttacks: 'Prevented Attacks',
      reportsInRetest: 'Reports in Retest',
      newReports: 'New Reports',
      inAnalysis: 'In Analysis',
      closed: 'Closed',
      impact_distribution: 'Impact Distribution',
      total: 'Total',
      top_vulnerabilities: 'Top Vulnerabilities',
      loading: 'Loading...',
      error_loading: 'Error loading data',
      critical: 'Critical',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      filters: {
        impact_level: 'Impact Level',
        status: 'Status',
        category: 'Category',
        application: 'Application',
        select_application: 'Select an application',
        application_placeholder: 'Select an application',
        no_applications: 'No applications found',
        all: 'All',
        type: 'Type',
        date: {
          from: 'From',
          to: 'To',
          search: 'Search'
        }
      },
      table: {
        report_name: 'Report Name',
        application: 'Application',
        date: 'Date',
        type: 'Type',
        status: 'Status',
        impact: 'Impact'
      },
      impact: {
        critical: 'Critical',
        high: 'High',
        medium: 'Medium',
        low: 'Low'
      },
      status: {
        new: 'New',
        under_review: 'Under Review',
        confirmed: 'Confirmed',
        awaiting_fix: 'Awaiting Fix',
        in_mitigation: 'In Mitigation',
        awaiting_retest: 'Awaiting Retest',
        retest_done: 'Retest Done',
        fixed: 'Fixed',
        closed: 'Closed'
      },
      category: {
        broken_access_control: 'Broken Access Control',
        cryptographic_failures: 'Cryptographic Failures',
        injection: 'Injection',
        xss: 'Cross-Site Scripting (XSS)',
        insecure_design: 'Insecure Design',
        security_misconfiguration: 'Security Misconfiguration',
        vulnerable_outdated_components: 'Vulnerable and Outdated Components',
        identification_authentication_failures: 'Identification and Authentication Failures',
        software_data_integrity_failures: 'Software and Data Integrity Failures',
        security_logging_monitoring_failures: 'Security Logging and Monitoring Failures',
        ssrf: 'Server-Side Request Forgery (SSRF)',
        other: 'Other'
      }
    },
    reports: {
      title: 'Vulnerability Reports',
      filters: {
        date: {
          from: 'From',
          to: 'To',
          search: 'Search'
        },
        impact_level: {
          label: 'Impact Level',
          all: 'All',
          critical: 'Critical',
          high: 'High',
          medium: 'Medium',
          low: 'Low'
        },
        status: {
          label: 'Status',
          all: 'All',
          new: 'New',
          under_review: 'Under Review',
          confirmed: 'Confirmed',
          awaiting_fix: 'Awaiting Fix',
          in_mitigation: 'In Mitigation',
          awaiting_retest: 'Awaiting Retest',
          retest_done: 'Retest Done',
          fixed: 'Fixed',
          closed: 'Closed'
        },
        category: {
          label: 'Category',
          all: 'All',
          broken_access_control: 'Broken Access Control',
          cryptographic_failures: 'Cryptographic Failures',
          injection: 'Injection',
          xss: 'Cross-Site Scripting (XSS)',
          insecure_design: 'Insecure Design',
          security_misconfiguration: 'Security Misconfiguration',
          vulnerable_outdated_components: 'Vulnerable and Outdated Components',
          identification_authentication_failures: 'Identification and Authentication Failures',
          software_data_integrity_failures: 'Software and Data Integrity Failures',
          security_logging_monitoring_failures: 'Security Logging and Monitoring Failures',
          ssrf: 'Server-Side Request Forgery (SSRF)',
          other: 'Other'
        },
        service: {
          monitoring: 'Monitoramento',
          pentest: 'Pentest',
          redteam: 'Red Team',
          vulnerability: 'Vulnerabilidade'
        }
      },
      table: {
        date: 'Date',
        report_name: 'Report Name',
        type: 'Type',
        status: 'Status',
        impact: 'Impact',
        service: 'Service'
      }
    },
    company_select: {
      title: 'Select Organization',
      subtitle: 'Choose the organization you want to access',
      company_id: 'Organization ID',
      management_console: 'Management console',
      programmatic_access: 'Programmatic access',
      manage_account: 'Manage Organization'
    },
    report_details: {
      vulnerability_details: 'Vulnerability Details',
      reproduction_steps: 'Reproduction Steps',
      manage_report: 'Manage Report',
      movements: 'Movements',
      report_date: 'Report Date',
      category: 'Category',
      application: 'Application',
      application_name: 'Application Name',
      application_host: 'Application Host',
      identifier: 'Identificador',
      status: 'Status',
      responsible: 'Responsible',
      criticality: 'Criticality',
      select_responsible: 'Select a responsible',
      select_status: 'Select a status',
      add_note: 'Add a note...',
      save_changes: 'Save Changes',
      status_changed: 'Status changed to',
      responsible_changed: 'Responsible changed to',
      by: 'by',
      close: 'Close',
      movements_timeline: {
        status_change: 'Status changed to',
        responsible_change: 'Responsible changed to',
        by: 'by',
        note: 'Note'
      },
      solutions: 'Soluções',
      no_solutions: 'Nenhuma solução registrada'
    },
    applications: {
      add: 'Add Application',
      modal: {
        title: 'New Application'
      },
      form: {
        host: 'Host',
        hostPlaceholder: 'Enter the application host (e.g. https://example.com)',
        applicationType: 'Application Type',
        operationType: 'Operation Type'
      },
      types: {
        web: 'Web Application',
        mobile: 'Mobile Application',
        api: 'API'
      },
      operations: {
        blackbox: 'Black Box',
        greybox: 'Grey Box',
        whitebox: 'White Box'
      },
      tooltips: {
        blackBox: 'Testing without source code access, simulating an external attack',
        greyBox: 'Testing with partial access to source code and documentation',
        whiteBox: 'Testing with full access to source code and documentation'
      },
      table: {
        application: 'Application',
        host: 'Host',
        operation: 'Operation',
        monitoring: 'Monitoring'
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
        monitoring: 'Monitoring'
      },
      menu: {
        applications: 'Applications'
      },
      connect_github: 'Connect GitHub',
      github_connected: 'GitHub Connected',
      github_integration: 'GitHub Integration',
      repository: 'Repository',
      branch: 'Branch',
      generate_pat: 'Gerar Token de Acesso Pessoal',
      pat_instructions: 'Gere um Fine-Grained PAT com as seguintes permissões:',
      generate_pat_link: 'Gerar novo token no GitHub',
      github_token: 'Token do GitHub',
      enter_github_token: 'Cole seu token aqui',
      github_repository: 'Repositório',
      repository_format_help: 'Digite no formato: dono/repositório (exemplo: usuario/projeto)',
      repository_format_error: 'Formato inválido. Use: dono/repositório',
      token_invalid: 'Token inválido ou sem permissões necessárias',
      repository_not_found: 'Repositório não encontrado. Verifique se o nome está correto e se você tem acesso.',
      required_permissions: 'Permissões necessárias',
      permissions_help: 'Todas as permissões devem ser configuradas como "Read"',
      token_missing_permissions: 'O token não possui todas as permissões necessárias. Por favor, verifique se todas as permissões foram concedidas.',
      permissions: {
        contents: 'Para acessar código fonte e arquivos',
        metadata: 'Para informações básicas do repositório',
        pull_requests: 'Para monitorar mudanças via PRs',
        code_scanning: 'Para alertas de análise de código',
        dependabot: 'Para alertas de vulnerabilidades',
        secret_scanning: 'Para detecção de secrets expostos',
        workflows: 'Para análise de CI/CD (aceita Read ou Write)'
      },
      connection_error: 'Erro ao conectar com GitHub. Verifique o token e as permissões.',
      repository_access_error: 'Token não tem permissão de acesso ao repositório',
      permissions_instructions: 'No GitHub, ao criar o Fine-Grained PAT, selecione as seguintes permissões:',
      token_insufficient_permission: 'O token precisa ter pelo menos permissão de leitura no repositório',
      test_access: 'Testar Acesso ao GitHub',
      testing_access: 'Testando Acesso...',
      test_success: 'Todos os testes de acesso foram concluídos com sucesso!',
      test_error: 'Erro ao testar acesso ao repositório'
    },
    menu: {
      applications: 'Applications'
    },
    profile: {
      title: 'Profile',
      name: 'Name',
      email: 'Email',
      company: 'Organization',
      role: 'Role',
      created_at: 'Registration Date'
    },
    module_management: {
      title: 'Module Management',
      description: 'Manage the modules available to the client. Here you can enable or disable modules that the company will have access to. Disabled modules will not be available to any user in the client\'s organization.',
      nav_description: 'Manage modules available to clients',
      applications: {
        name: 'Aplicações',
        description: 'Gerencie suas aplicações web, mobile e APIs'
      },
      monitoring: {
        name: 'Monitoring',
        description: 'Continuous vulnerability monitoring'
      }
    },
    totp: {
      title: 'Two-Factor Authentication Setup',
      description: 'Enter the 6-digit code from your authenticator app',
      description_setup: 'Scan the QR Code with your authenticator app (Google Authenticator or Microsoft Authenticator) and enter the generated code',
      code_placeholder: 'Enter code',
      verify_button: 'Verify',
      verifying: 'Verifying...',
      invalid_code_length: 'Code must be 6 digits',
      error_verify: 'Error verifying code. Please try again.',
      error_setup: 'Error setting up two-factor authentication',
      success: 'Two-factor authentication completed successfully',
      scan_qr: 'Scan this QR Code with your authenticator app',
      after_scan: 'After scanning, enter the code shown in the app',
      setup_required: 'Configure two-factor authentication to continue',
      authenticator_apps: 'Recommended apps: Google Authenticator, Microsoft Authenticator',
      title_info: 'A autenticação em duas etapas adiciona uma camada extra de segurança à sua conta. Além da senha, você precisará de um código gerado por um aplicativo autenticador.'
    }
  },
  'pt-pt': {
    common: {
      search: 'Procurar',
      from: 'De',
      to: 'Até',
      cancel: 'Cancelar',
      save: 'Guardar',
      loading: 'Carregando...',
      view_all: 'Ver todas'
    },
    labels: {
      vulnerability_reports: 'Relatórios de Vulnerabilidade',
    },
    report_status: {
      new: 'Novo',
      under_review: 'Em Análise',
    },
    report_service: {
      'Bug Bounty': 'Bug Bounty',
      'Monitoring': 'Monitorização',
      'Pentest': 'Pentest'
    },
    login: {
      title: 'Aceder à sua conta',
      email_placeholder: 'Digite o seu e-mail',
      password_placeholder: 'Digite a sua palavra-passe',
      forgot_password: 'Esqueceu a sua palavra-passe?',
      submit: 'Aceder',
      no_account: 'Não tem uma conta?',
      contact_us: 'Contacte-nos',
      loading: 'Aguarde...'
    },
    home: {
      welcome: 'Bem-vindo, {{userName}}!',
      help: 'Como podemos ajudá-lo hoje?',
      solutions: 'Soluções',
      applications: 'Aplicações',
      addApplication: 'Adicionar Aplicação',
      service: 'Serviço',
      type: 'Tipo',
      status: 'Estado',
      lastUpdate: 'Última atualização',
      services: {
        applications: {
          name: 'Aplicações',
          description: 'Faça a gestão das suas aplicações, adicione novas e monitorize o estado'
        },
        monitoring: {
          name: 'Monitorização de Aplicações',
          description: 'Monitorização contínua de vulnerabilidades nas suas aplicações'
        },
        pentest: {
          name: 'Pentest',
          description: 'Testes de penetração manuais e automatizados'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas por vulnerabilidades'
        }
      }
    },
    navbar: {
      solutions: {
        title: 'Soluções',
        applications: {
          name: 'Aplicações',
          description: 'Faça a gestão das suas aplicações, adicione novas e monitorize o estado'
        },
        reports: {
          name: 'Relatórios',
          description: 'Visualize e faça a gestão de relatórios de vulnerabilidades'
        },
        monitoring: {
          name: 'Monitorização de Aplicações',
          description: 'Monitorização contínua de vulnerabilidades'
        },
        codeScanning: {
          name: 'Code Scanning',
          description: 'Análise estática automatizada de código fonte'
        },
        cloudSecurity: {
          name: 'Cloud Security',
          description: 'Proteção e monitoramento de ambientes em nuvem'
        },
        pentest: {
          name: 'Pentest',
          description: 'Teste de penetração em aplicações web e mobile'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas'
        },
        active_solution: 'SOLUÇÃO ATIVA',
        not_active: 'NÃO ATIVADA'
      },
      profile: 'Perfil',
      settings: 'Definições',
      logout: 'Sair'
    },
    monitoring: {
      dashboard: 'Dashboard',
      reports: 'Relatórios',
      insights: 'Insights',
      monitoringDashboard: 'Dashboard de Monitorização',
      monitoringInsights: 'Insights de Monitorização',
      monitoredApplications: 'Aplicações Monitoradas',
      preventedAttacks: 'Ataques Prevenidos',
      reportsInRetest: 'Relatórios em Reteste',
      newReports: 'Novos Relatórios',
      inAnalysis: 'Em Análise',
      closed: 'Fechados',
      impact_distribution: 'Distribuição por Impacto',
      total: 'Total',
      top_vulnerabilities: 'Top Vulnerabilidades',
      loading: 'Carregando...',
      error_loading: 'Erro ao carregar dados',
      critical: 'Crítico',
      high: 'Alto',
      medium: 'Médio',
      low: 'Baixo',
      filters: {
        impact_level: 'Nível de Impacto',
        status: 'Status',
        category: 'Categoria',
        application: 'Aplicação',
        select_application: 'Selecione uma aplicação',
        application_placeholder: 'Selecione uma aplicação',
        no_applications: 'Nenhuma aplicação encontrada',
        all: 'Todos',
        type: 'Tipo',
        date: {
          from: 'De',
          to: 'Até',
          search: 'Procurar'
        }
      },
      table: {
        report_name: 'Nome do Relatório',
        date: 'Data',
        type: 'Tipo',
        status: 'Status',
        impact: 'Impacto'
      },
      impact: {
        critical: 'Crítico',
        high: 'Alto',
        medium: 'Médio',
        low: 'Baixo'
      },
      status: {
        new: 'Novo',
        under_review: 'Em Análise',
        confirmed: 'Confirmado',
        awaiting_fix: 'A Aguardar Correção',
        in_mitigation: 'Em Mitigação',
        awaiting_retest: 'A Aguardar Reteste',
        retest_done: 'Reteste Concluído',
        fixed: 'Corrigido',
        closed: 'Fechado'
      },
      category: {
        broken_access_control: 'Controlo de Acesso Quebrado',
        cryptographic_failures: 'Falhas Criptográficas',
        injection: 'Injeção',
        xss: 'Cross-Site Scripting (XSS)',
        insecure_design: 'Design Inseguro',
        security_misconfiguration: 'Configuração Incorreta de Segurança',
        vulnerable_outdated_components: 'Componentes Vulneráveis e Desatualizados',
        identification_authentication_failures: 'Falhas de Identificação e Autenticação',
        software_data_integrity_failures: 'Falhas de Integridade de Software e Dados',
        security_logging_monitoring_failures: 'Falhas de Registo e Monitorização',
        ssrf: 'Server-Side Request Forgery (SSRF)',
        other: 'Outros'
      }
    },
    reports: {
      title: 'Relatórios de Vulnerabilidade',
      filters: {
        date: {
          from: 'De',
          to: 'Até',
          search: 'Procurar'
        },
        impact_level: {
          label: 'Nível de Impacto',
          all: 'Todos',
          critical: 'Crítico',
          high: 'Alto',
          medium: 'Médio',
          low: 'Baixo'
        },
        status: {
          label: 'Estado',
          all: 'Todos',
          new: 'Novo',
          under_review: 'Em Análise',
          confirmed: 'Confirmado',
          awaiting_fix: 'A Aguardar Correção',
          in_mitigation: 'Em Mitigação',
          awaiting_retest: 'A Aguardar Reteste',
          retest_done: 'Reteste Concluído',
          fixed: 'Corrigido',
          closed: 'Fechado'
        },
        category: {
          label: 'Categoria',
          all: 'Todos',
          broken_access_control: 'Controlo de Acesso Quebrado',
          cryptographic_failures: 'Falhas Criptográficas',
          injection: 'Injeção',
          xss: 'Cross-Site Scripting (XSS)',
          insecure_design: 'Design Inseguro',
          security_misconfiguration: 'Configuração Incorreta de Segurança',
          vulnerable_outdated_components: 'Componentes Vulneráveis e Desatualizados',
          identification_authentication_failures: 'Falhas de Identificação e Autenticação',
          software_data_integrity_failures: 'Falhas de Integridade de Software e Dados',
          security_logging_monitoring_failures: 'Falhas de Registo e Monitorização',
          ssrf: 'Server-Side Request Forgery (SSRF)',
          other: 'Outros'
        },
        service: {
          monitoring: 'Monitorização',
          pentest: 'Pentest',
          redteam: 'Red Team',
          vulnerability: 'Vulnerabilidade'
        }
      },
      table: {
        date: 'Data',
        report_name: 'Nome do Relatório',
        type: 'Tipo',
        status: 'Estado',
        impact: 'Impacto',
        service: 'Serviço'
      }
    },
    company_select: {
      title: 'Selecionar Organização',
      subtitle: 'Escolha a organização que pretende aceder',
      company_id: 'ID da organização',
      management_console: 'Consola de gestão',
      programmatic_access: 'Acesso programático',
      manage_account: 'Gerir Organização'
    },
    report_details: {
      vulnerability_details: 'Detalhes da Vulnerabilidade',
      reproduction_steps: 'Passos para Reprodução',
      manage_report: 'Gerir Relatório',
      movements: 'Movimentações',
      report_date: 'Data do Relatório',
      category: 'Categoria',
      application: 'Aplicação',
      application_name: 'Nome da Aplicação',
      application_host: 'Host da Aplicação',
      identifier: 'Identificador',
      status: 'Estado',
      responsible: 'Responsável',
      criticality: 'Criticidade',
      select_responsible: 'Selecione um responsável',
      select_status: 'Selecione um estado',
      add_note: 'Adicione uma anotação...',
      save_changes: 'Guardar Alterações',
      status_changed: 'Estado alterado para',
      responsible_changed: 'Responsável alterado para',
      by: 'por',
      close: 'Fechar',
      movements_timeline: {
        status_change: 'Estado alterado para',
        responsible_change: 'Responsável alterado para',
        by: 'por',
        note: 'Anotação'
      },
      solutions: 'Soluções',
      no_solutions: 'Nenhuma solução registrada'
    },
    applications: {
      add: 'Adicionar Aplicação',
      modal: {
        title: 'Nova Aplicação'
      },
      form: {
        host: 'Host',
        hostPlaceholder: 'Digite o host da aplicação (ex: https://exemplo.com)',
        applicationType: 'Tipo de Aplicação',
        operationType: 'Tipo de Operação'
      },
      types: {
        web: 'Aplicação Web',
        mobile: 'Aplicação Mobile',
        api: 'API'
      },
      operations: {
        blackbox: 'Black Box',
        greybox: 'Grey Box',
        whitebox: 'White Box'
      },
      tooltips: {
        blackBox: 'Teste sem acesso ao código fonte, simulando um ataque externo',
        greyBox: 'Teste com acesso parcial ao código fonte e documentação',
        whiteBox: 'Teste com acesso completo ao código fonte e documentação'
      },
      table: {
        application: 'Aplicação',
        host: 'Host',
        operation: 'Operação',
        monitoring: 'Monitoramento'
      },
      status: {
        active: 'Ativo',
        inactive: 'Inativo',
        monitoring: 'Monitorando'
      },
      menu: {
        applications: 'Aplicações'
      },
      connect_github: 'Conectar GitHub',
      github_connected: 'GitHub Conectado',
      github_integration: 'Integração com GitHub',
      repository: 'Repositório',
      branch: 'Branch',
      generate_pat: 'Gerar Token de Acesso Pessoal',
      pat_instructions: 'Gere um Fine-Grained PAT com as seguintes permissões:',
      generate_pat_link: 'Gerar novo token no GitHub',
      github_token: 'Token do GitHub',
      enter_github_token: 'Cole seu token aqui',
      github_repository: 'Repositório',
      repository_format_help: 'Digite no formato: dono/repositório (exemplo: usuario/projeto)',
      repository_format_error: 'Formato inválido. Use: dono/repositório',
      token_invalid: 'Token inválido ou sem permissões necessárias',
      repository_not_found: 'Repositório não encontrado. Verifique se o nome está correto e se você tem acesso.',
      required_permissions: 'Permissões necessárias',
      permissions_help: 'Todas as permissões devem ser configuradas como "Read"',
      token_missing_permissions: 'O token não possui todas as permissões necessárias. Por favor, verifique se todas as permissões foram concedidas.',
      permissions: {
        contents: 'Para acessar código fonte e arquivos',
        metadata: 'Para informações básicas do repositório',
        pull_requests: 'Para monitorar mudanças via PRs',
        code_scanning: 'Para alertas de análise de código',
        dependabot: 'Para alertas de vulnerabilidades',
        secret_scanning: 'Para detecção de secrets expostos',
        workflows: 'Para análise de CI/CD (aceita Read ou Write)'
      },
      connection_error: 'Erro ao conectar com GitHub. Verifique o token e as permissões.',
      repository_access_error: 'Token não tem permissão de acesso ao repositório',
      permissions_instructions: 'No GitHub, ao criar o Fine-Grained PAT, selecione as seguintes permissões:',
      token_insufficient_permission: 'O token precisa ter pelo menos permissão de leitura no repositório',
      test_access: 'Testar Acesso ao GitHub',
      testing_access: 'Testando Acesso...',
      test_success: 'Todos os testes de acesso foram concluídos com sucesso!',
      test_error: 'Erro ao testar acesso ao repositório'
    },
    menu: {
      applications: 'Aplicações'
    },
    profile: {
      title: 'Perfil',
      name: 'Nome',
      email: 'E-mail',
      company: 'Organização',
      role: 'Cargo',
      created_at: 'Data de Cadastro'
    },
    module_management: {
      title: 'Gerenciamento de Módulos',
      description: 'Gerencie os módulos disponíveis para o cliente. Aqui você pode ativar ou desativar os módulos que a empresa terá acesso. Os módulos desativados não estarão disponíveis para nenhum usuário da organização do cliente.',
      nav_description: 'Gerencie os módulos disponíveis para os clientes',
      applications: {
        name: 'Aplicações',
        description: 'Gerencie suas aplicações web, mobile e APIs'
      },
      monitoring: {
        name: 'Monitoramento',
        description: 'Monitoramento contínuo de vulnerabilidades'
      }
    },
    totp: {
      title: 'Configuração de Autenticação em Duas Etapas',
      description: 'Digite o código de 6 dígitos do seu aplicativo autenticador',
      description_setup: 'Escaneie o QR Code com seu aplicativo autenticador (Google Authenticator ou Microsoft Authenticator) e digite o código gerado',
      code_placeholder: 'Digite o código',
      verify_button: 'Verificar',
      verifying: 'Verificando...',
      invalid_code_length: 'O código deve ter 6 dígitos',
      error_verify: 'Erro ao verificar código. Tente novamente.',
      error_setup: 'Erro ao configurar autenticação em duas etapas',
      success: 'Autenticação em duas etapas concluída com sucesso',
      scan_qr: 'Escaneie este QR Code com seu aplicativo autenticador',
      after_scan: 'Após escanear, digite o código que aparecer no aplicativo',
      setup_required: 'Configure a autenticação em duas etapas para continuar',
      authenticator_apps: 'Aplicativos recomendados: Google Authenticator, Microsoft Authenticator',
      title_info: 'A autenticação em duas etapas adiciona uma camada extra de segurança à sua conta. Além da senha, você precisará de um código gerado por um aplicativo autenticador.'
    }
  },
  'es': {
    common: {
      search: 'Buscar',
      from: 'Desde',
      to: 'Hasta',
      cancel: 'Cancelar',
      save: 'Guardar',
      loading: 'Loading...',
      view_all: 'Ver todas'
    },
    labels: {
      vulnerability_reports: 'Informes de Vulnerabilidad',
    },
    report_status: {
      new: 'Nuevo',
      under_review: 'En Revisión',
    },
    report_service: {
      'Bug Bounty': 'Bug Bounty',
      'Monitoring': 'Monitoreo',
      'Pentest': 'Pentest'
    },
    login: {
      title: 'Inicie sesión en su cuenta Wehack.',
      email_placeholder: 'Ingrese su correo electrónico',
      password_placeholder: 'Ingrese su contraseña',
      forgot_password: '¿Olvidó su contraseña?',
      submit: 'Access',
      no_account: '¿No tiene una cuenta?',
      contact_us: 'Contáctenos',
      loading: 'Please wait...'
    },
    home: {
      welcome: '¡Bienvenido, {{userName}}!',
      help: '¿Cómo podemos ayudarte hoy?',
      solutions: 'Soluciones',
      applications: 'Aplicaciones',
      addApplication: 'Agregar Aplicación',
      service: 'Servicio',
      type: 'Tipo',
      status: 'Estado',
      lastUpdate: 'Última actualización',
      services: {
        applications: {
          name: 'Aplicaciones',
          description: 'Gestione sus aplicaciones, agregue nuevas y monitora el estado'
        },
        monitoring: {
          name: 'Monitoreo de Aplicaciones',
          description: 'Monitoreo continuo de vulnerabilidades en sus aplicaciones'
        },
        pentest: {
          name: 'Pentest',
          description: 'Manual and automated penetration testing'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas por vulnerabilidades'
        }
      }
    },
    navbar: {
      solutions: {
        title: 'Soluciones',
        applications: {
          name: 'Aplicaciones',
          description: 'Gestione sus aplicaciones, agregue nuevas y monitora el estado'
        },
        reports: {
          name: 'Informes',
          description: 'Visualice y gestione informes de vulnerabilidades'
        },
        monitoring: {
          name: 'Monitoreo de Aplicaciones',
          description: 'Monitoreo continuo de vulnerabilidades'
        },
        codeScanning: {
          name: 'Code Scanning',
          description: 'Análise estática automatizada de código fonte'
        },
        cloudSecurity: {
          name: 'Cloud Security',
          description: 'Protection and monitoring of cloud environments'
        },
        pentest: {
          name: 'Pentest',
          description: 'Pruebas de penetración en aplicaciones web y móviles'
        },
        bugBounty: {
          name: 'Bug Bounty',
          description: 'Programa de recompensas'
        },
        active_solution: 'SOLUCIÓN ACTIVA',
        not_active: 'NO ACTIVADA'
      },
      profile: 'Perfil',
      settings: 'Configuración',
      logout: 'Cerrar Sesión'
    },
    monitoring: {
      dashboard: 'Panel',
      reports: 'Informes',
      insights: 'Insights',
      monitoringDashboard: 'Panel de Monitoreo',
      monitoringInsights: 'Insights de Monitoreo',
      monitoredApplications: 'Monitored Applications',
      preventedAttacks: 'Prevented Attacks',
      reportsInRetest: 'Reports in Retest',
      newReports: 'New Reports',
      inAnalysis: 'In Analysis',
      closed: 'Closed',
      impact_distribution: 'Impact Distribution',
      total: 'Total',
      top_vulnerabilities: 'Top Vulnerabilities',
      loading: 'Loading...',
      error_loading: 'Error loading data',
      critical: 'Critical',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      filters: {
        impact_level: 'Nivel de Impacto',
        status: 'Estado',
        category: 'Categoría',
        application: 'Aplicación',
        select_application: 'Seleccione una aplicación',
        application_placeholder: 'Seleccione una aplicación',
        no_applications: 'No se encontraron aplicaciones',
        all: 'Todos',
        type: 'Tipo',
        date: {
          from: 'Desde',
          to: 'Hasta',
          search: 'Buscar'
        }
      },
      table: {
        report_name: 'Nombre del Informe',
        date: 'Fecha',
        type: 'Tipo',
        status: 'Estado',
        impact: 'Impacto'
      },
      impact: {
        critical: 'Crítico',
        high: 'Alto',
        medium: 'Medio',
        low: 'Bajo'
      },
      status: {
        new: 'Nuevo',
        under_review: 'En Revisión',
        confirmed: 'Confirmado',
        awaiting_fix: 'Esperando Corrección',
        in_mitigation: 'En Mitigación',
        awaiting_retest: 'Esperando Retesteo',
        retest_done: 'Retesteo Completado',
        fixed: 'Corregido',
        closed: 'Cerrado'
      },
      category: {
        broken_access_control: 'Control de Acceso Roto',
        cryptographic_failures: 'Fallos Criptográficos',
        injection: 'Inyección',
        xss: 'Cross-Site Scripting (XSS)',
        insecure_design: 'Diseño Inseguro',
        security_misconfiguration: 'Configuración Incorrecta de Seguridad',
        vulnerable_outdated_components: 'Componentes Vulnerables y Desactualizados',
        identification_authentication_failures: 'Fallos de Identificación y Autenticación',
        software_data_integrity_failures: 'Fallos de Integridad de Software y Datos',
        security_logging_monitoring_failures: 'Fallos de Registro y Monitoreo de Seguridad',
        ssrf: 'Server-Side Request Forgery (SSRF)',
        other: 'Otros'
      }
    },
    reports: {
      title: 'Informes de Vulnerabilidad',
      filters: {
        date: {
          from: 'Desde',
          to: 'Hasta',
          search: 'Buscar'
        },
        impact_level: {
          label: 'Nível de Impacto',
          all: 'Todos',
          critical: 'Crítico',
          high: 'Alto',
          medium: 'Medio',
          low: 'Bajo'
        },
        status: {
          label: 'Estado',
          all: 'Todos',
          new: 'Nuevo',
          under_review: 'En Revisión',
          confirmed: 'Confirmado',
          awaiting_fix: 'Esperando Corrección',
          in_mitigation: 'En Mitigación',
          awaiting_retest: 'Esperando Retesteo',
          retest_done: 'Retesteo Completado',
          fixed: 'Corregido',
          closed: 'Cerrado'
        },
        category: {
          label: 'Categoria',
          all: 'Todos',
          broken_access_control: 'Control de Acceso Roto',
          cryptographic_failures: 'Fallos Criptográficos',
          injection: 'Inyección',
          xss: 'Cross-Site Scripting (XSS)',
          insecure_design: 'Diseño Inseguro',
          security_misconfiguration: 'Configuración Incorrecta de Seguridad',
          vulnerable_outdated_components: 'Componentes Vulnerables y Desactualizados',
          identification_authentication_failures: 'Fallos de Identificación y Autenticación',
          software_data_integrity_failures: 'Fallos de Integridad de Software y Datos',
          security_logging_monitoring_failures: 'Fallos de Registro y Monitoreo de Seguridad',
          ssrf: 'Server-Side Request Forgery (SSRF)',
          other: 'Otros'
        },
        service: {
          monitoring: 'Monitorização',
          pentest: 'Pentest',
          redteam: 'Red Team',
          vulnerability: 'Vulnerabilidade'
        }
      },
      table: {
        date: 'Data',
        report_name: 'Nome do Relatório',
        type: 'Tipo',
        status: 'Estado',
        impact: 'Impacto',
        service: 'Serviço'
      }
    },
    company_select: {
      title: 'Seleccionar Organización',
      subtitle: 'Elija la organización a la que desea acceder',
      company_id: 'ID de la organización',
      management_console: 'Consola de gestión',
      programmatic_access: 'Acceso programático',
      manage_account: 'Gestionar Organización'
    },
    report_details: {
      vulnerability_details: 'Detalles de la Vulnerabilidad',
      reproduction_steps: 'Pasos de Reproducción',
      manage_report: 'Gestionar Informe',
      movements: 'Movimientos',
      report_date: 'Fecha del Informe',
      category: 'Categoría',
      application: 'Aplicación',
      application_name: 'Nombre de la Aplicación',
      application_host: 'Host de la Aplicación',
      identifier: 'Identificador',
      status: 'Estado',
      responsible: 'Responsable',
      criticality: 'Criticidad',
      select_responsible: 'Seleccione un responsable',
      select_status: 'Seleccione un estado',
      add_note: 'Agregar una nota...',
      save_changes: 'Guardar Cambios',
      status_changed: 'Estado cambiado a',
      responsible_changed: 'Responsable cambiato a',
      by: 'por',
      close: 'Cerrar',
      movements_timeline: {
        status_change: 'Estado cambiado a',
        responsible_change: 'Responsable cambiato a',
        by: 'por',
        note: 'Nota'
      },
      solutions: 'Soluções',
      no_solutions: 'Nenhuma solução registrada'
    },
    applications: {
      add: 'Add Application',
      modal: {
        title: 'New Application'
      },
      form: {
        host: 'Host',
        hostPlaceholder: 'Enter the application host (e.g. https://example.com)',
        applicationType: 'Application Type',
        operationType: 'Operation Type'
      },
      types: {
        web: 'Web Application',
        mobile: 'Mobile Application',
        api: 'API'
      },
      operations: {
        blackbox: 'Black Box',
        greybox: 'Grey Box',
        whitebox: 'White Box'
      },
      tooltips: {
        blackBox: 'Testing without source code access, simulating an external attack',
        greyBox: 'Testing with partial access to source code and documentation',
        whiteBox: 'Testing with full access to source code and documentation'
      },
      table: {
        application: 'Application',
        host: 'Host',
        operation: 'Operation',
        monitoring: 'Monitoring'
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
        monitoring: 'Monitoring'
      },
      menu: {
        applications: 'Aplicaciones'
      },
      connect_github: 'Connect GitHub',
      github_connected: 'GitHub Connected',
      github_integration: 'GitHub Integration',
      repository: 'Repository',
      branch: 'Branch',
      generate_pat: 'Gerar Token de Acesso Pessoal',
      pat_instructions: 'Gere um Fine-Grained PAT com as siguientes permissões:',
      generate_pat_link: 'Gerar novo token no GitHub',
      github_token: 'Token do GitHub',
      enter_github_token: 'Cole seu token aqui',
      github_repository: 'Repositório',
      repository_format_help: 'Digite no formato: dono/repositório (exemplo: usuario/projeto)',
      repository_format_error: 'Formato inválido. Use: dono/repositório',
      token_invalid: 'Token inválido ou sem permissões necessárias',
      repository_not_found: 'Repositório não encontrado. Verifique se o nome está correto e se você tem acesso.',
      required_permissions: 'Permissões necessárias',
      permissions_help: 'Todas as permissões devem ser configuradas como "Read"',
      token_missing_permissions: 'O token não possui todas as permissões necessárias. Por favor, verifique se todas as permissões foram concedidas.',
      permissions: {
        contents: 'Para acessar código fonte e arquivos',
        metadata: 'Para informações básicas do repositório',
        pull_requests: 'Para monitorar mudanças via PRs',
        code_scanning: 'Para alertas de análise de código',
        dependabot: 'Para alertas de vulnerabilidades',
        secret_scanning: 'Para detecção de secrets expostos',
        workflows: 'Para análise de CI/CD (aceita Read ou Write)'
      },
      connection_error: 'Erro ao conectar com GitHub. Verifique o token e as permissões.',
      repository_access_error: 'Token não tem permissão de acesso ao repositório',
      permissions_instructions: 'No GitHub, ao criar o Fine-Grained PAT, selecione as seguintes permissões:',
      token_insufficient_permission: 'O token precisa ter pelo menos permissão de leitura no repositório',
      test_access: 'Testar Acesso ao GitHub',
      testing_access: 'Testando Acesso...',
      test_success: 'Todos os testes de acesso foram concluídos com sucesso!',
      test_error: 'Erro ao testar acesso ao repositório'
    },
    menu: {
      applications: 'Aplicaciones'
    },
    profile: {
      title: 'Perfil',
      name: 'Nombre',
      email: 'Correo electrónico',
      company: 'Organización',
      role: 'Cargo',
      created_at: 'Fecha de registro'
    },
    module_management: {
      title: 'Gerenciamento de Módulos',
      description: 'Gerencie os módulos disponíveis para o cliente. Aqui você pode ativar ou desativar os módulos que a empresa terá acesso. Os módulos desativados não estarão disponíveis para nenhum usuário da organização do cliente.',
      description_details: 'Cada módulo oferece funcionalidades específicas:',
      applications: {
        name: 'Aplicações',
        description: 'Gerencie suas aplicações web, mobile e APIs'
      },
      monitoring: {
        name: 'Monitoramento',
        description: 'Monitoramento contínuo de vulnerabilidades'
      }
    },
    totp: {
      title: 'Configuração de Autenticação em Duas Etapas',
      description: 'Digite o código de 6 dígitos do seu aplicativo autenticador',
      description_setup: 'Escaneie o QR Code com seu aplicativo autenticador (Google Authenticator ou Microsoft Authenticator) e digite o código gerado',
      code_placeholder: 'Digite o código',
      verify_button: 'Verificar',
      verifying: 'Verificando...',
      invalid_code_length: 'O código deve ter 6 dígitos',
      error_verify: 'Erro ao verificar código. Tente novamente.',
      error_setup: 'Erro ao configurar autenticação em duas etapas',
      success: 'Autenticação em duas etapas concluída com sucesso',
      scan_qr: 'Escaneie este QR Code com seu aplicativo autenticador',
      after_scan: 'Após escanear, digite o código que aparecer no aplicativo',
      setup_required: 'Configure a autenticação em duas etapas para continuar',
      authenticator_apps: 'Aplicativos recomendados: Google Authenticator, Microsoft Authenticator',
      title_info: 'A autenticação em duas etapas adiciona uma camada extra de segurança à sua conta. Além da senha, você precisará de um código gerado por um aplicativo autenticador.'
    }
  }
};

export default translations; 