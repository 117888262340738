import { useState, useEffect } from 'react';
import axios from '../utils/axios';

export const useModules = () => {
  const [activeModules, setActiveModules] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchModules = async () => {
    try {
      const response = await axios.get('/modules/company');
      const modules = response.data;
      
      // Converte a lista de módulos em um objeto para fácil acesso
      const moduleMap = modules.reduce((acc, module) => {
        acc[module.code] = module.isActive;
        return acc;
      }, {});
      
      setActiveModules(moduleMap);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar módulos:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchModules();
  }, []);

  return { activeModules, setActiveModules, loading, refreshModules: fetchModules };
}; 