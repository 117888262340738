import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaGithub, FaShieldAlt, FaGlobe, FaMobile, FaServer, FaCode, FaExclamationCircle, FaChartBar, FaChevronRight, FaLock, FaLockOpen } from 'react-icons/fa';
import { RiComputerLine, RiSmartphoneLine, RiTerminalBoxLine } from 'react-icons/ri';
import { HiShieldCheck, HiShieldExclamation } from 'react-icons/hi';
import axios from '../utils/axios';
import { useLanguage } from '../LanguageContext';
import Footer from './Footer';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './ApplicationDetails.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ApplicationDetails = () => {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const [githubConnected, setGithubConnected] = useState(false);
  const [showGithubModal, setShowGithubModal] = useState(false);
  const [githubToken, setGithubToken] = useState('');
  const [githubRepository, setGithubRepository] = useState('');
  const [validatingToken, setValidatingToken] = useState(false);
  const [tokenError, setTokenError] = useState(null);
  const [activeTab, setActiveTab] = useState('open');
  const [vulnerabilityStats, setVulnerabilityStats] = useState({
    open: { critical: 0, high: 0, moderate: 0, low: 0 },
    closed: { critical: 0, high: 0, moderate: 0, low: 0 }
  });

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      try {
        if (!applicationId) {
          setError('ID da aplicação não fornecido');
          setLoading(false);
          return;
        }

        const response = await axios.get(`/applications/${applicationId}`);
        setApplication(response.data);
        
        setGithubConnected(!!response.data.github_repository && response.data.github_repository.length > 0);
        
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar detalhes da aplicação:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchApplicationDetails();
  }, [applicationId]);

  useEffect(() => {
    const fetchVulnerabilityStats = async () => {
      try {
        const response = await axios.get(`/applications/${applicationId}/vulnerabilities`);
        const vulnerabilities = response.data;
        
        const stats = vulnerabilities.reduce((acc, vuln) => {
          const isClosed = vuln.status === 'Closed';
          const category = isClosed ? 'closed' : 'open';
          
          let severity;
          const impact = parseFloat(vuln.impact);
          if (impact >= 9.0) severity = 'critical';
          else if (impact >= 7.0) severity = 'high';
          else if (impact >= 4.0) severity = 'moderate';
          else severity = 'low';
          
          acc[category][severity]++;
          return acc;
        }, {
          open: { critical: 0, high: 0, moderate: 0, low: 0 },
          closed: { critical: 0, high: 0, moderate: 0, low: 0 }
        });
        
        setVulnerabilityStats(stats);
      } catch (error) {
        console.error('Erro ao buscar estatísticas:', error);
        setVulnerabilityStats({
          open: { critical: 0, high: 0, moderate: 0, low: 0 },
          closed: { critical: 0, high: 0, moderate: 0, low: 0 }
        });
      }
    };

    fetchVulnerabilityStats();
  }, [applicationId]);

  const handleGithubConnect = () => {
    setShowGithubModal(true);
  };

  const validateAndSaveToken = async () => {
    try {
      setValidatingToken(true);
      setTokenError(null);

      // Validar formato do repositório
      if (!githubRepository.includes('/')) {
        setTokenError(translate('applications.repository_format_error'));
        return;
      }

      const response = await axios.post('/applications/github/connect', {
        applicationId,
        token: githubToken,
        repository: githubRepository
      });

      if (response.data.success) {
        setGithubConnected(true);
        setShowGithubModal(false);
        const appResponse = await axios.get(`/applications/${applicationId}`);
        setApplication(appResponse.data);
      }
    } catch (error) {
      console.error('Erro ao validar token:', error);
      let errorMessage = error.response?.data?.error;
      
      if (error.response?.status === 404) {
        errorMessage = translate('applications.repository_not_found');
      } else if (error.response?.status === 401) {
        errorMessage = translate('applications.token_invalid');
      } else {
        errorMessage = translate('applications.connection_error');
      }
      
      setTokenError(errorMessage);
    } finally {
      setValidatingToken(false);
    }
  };

  const getApplicationIcon = (type) => {
    switch (type) {
      case 'web application':
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
      case 'mobile application':
        return (
          <div className="icon-container mobile-app">
            <RiSmartphoneLine />
          </div>
        );
      case 'api':
        return (
          <div className="icon-container api-app">
            <RiTerminalBoxLine />
          </div>
        );
      default:
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
    }
  };

  const getFaviconUrl = (host) => {
    if (!host) return null;
    return `https://www.google.com/s2/favicons?domain=${host}&sz=64`;
  };

  if (loading) return <div className="loading">Carregando...</div>;
  if (error) return <div>Erro: {error}</div>;
  if (!application) return <div>Aplicação não encontrada</div>;

  return (
    <div className="application-details-container">
      <div className="breadcrumb">
        <Link to="/applications">Aplicações</Link>
        <FaChevronRight className="breadcrumb-separator" />
        <span>{application.application_name || application.host}</span>
      </div>

      <div className="application-header">
        <h1>
          {application.host && (
            <img 
              src={getFaviconUrl(application.host)}
              alt=""
              className="application-favicon"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
            />
          )}
          {application.application_name || application.host}
        </h1>
        <div className="application-actions">
          {!githubConnected ? (
            <button 
              className="github-connect-btn"
              onClick={handleGithubConnect}
            >
              <FaGithub />
              {translate('applications.connect_github')}
            </button>
          ) : (
            <div className="github-connected">
              <FaGithub />
              {translate('applications.github_connected')}
            </div>
          )}
        </div>
      </div>

      <div className="details-grid">
        <div className="detail-card">
          <div className="detail-icon">
            {getApplicationIcon(application.application_type)}
          </div>
          <div className="detail-info">
            <h3>Tipo de Aplicação</h3>
            <p>{translate(`applications.types.${application.application_type === 'web application' ? 'web' : application.application_type === 'mobile application' ? 'mobile' : 'api'}`)}</p>
          </div>
        </div>

        <div className="detail-card">
          <div className="detail-icon">
            {application.host ? (
              <img 
                src={`https://www.google.com/s2/favicons?domain=${application.host}&sz=64`}
                alt="Site favicon"
                className="favicon-icon"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/default-favicon.png'; // Ícone padrão caso falhe
                }}
              />
            ) : (
              <FaGlobe />
            )}
          </div>
          <div className="detail-info">
            <h3>Host</h3>
            <p>{application.host}</p>
          </div>
        </div>

        <div className="detail-card">
          <div className="detail-icon">
            <FaShieldAlt />
          </div>
          <div className="detail-info">
            <h3>Tipo de Operação</h3>
            <p>Black Box</p>
          </div>
        </div>
      </div>

      {/* Modal do GitHub */}
      {showGithubModal && (
        <div className="github-modal">
          <div className="github-modal-content">
            <h2>
              <FaGithub />
              {translate('applications.github_integration')}
            </h2>
            
            <div className="github-modal-steps">
              <h3>
                <span className="step-number">1</span>
                {translate('applications.generate_pat')}
              </h3>
              <p>{translate('applications.pat_instructions')}</p>
              
              <div className="permissions-list">
                <h4>
                  <FaShieldAlt />
                  {translate('applications.required_permissions')}
                </h4>
                <p>{translate('applications.permissions_instructions')}</p>
                <ul>
                  <li>Contents: Read</li>
                  <li>Metadata: Read</li>
                  <li>Pull requests: Read</li>
                </ul>
              </div>

              <a 
                href="https://github.com/settings/tokens?type=beta" 
                target="_blank" 
                rel="noopener noreferrer"
                className="github-link"
              >
                <FaGithub />
                {translate('applications.generate_pat_link')}
              </a>
            </div>

            <div className="form-group">
              <label>{translate('applications.github_token')}</label>
              <input
                type="password"
                value={githubToken}
                onChange={(e) => setGithubToken(e.target.value)}
                placeholder={translate('applications.enter_github_token')}
              />
            </div>

            <div className="form-group">
              <label>{translate('applications.github_repository')}</label>
              <input
                type="text"
                value={githubRepository}
                onChange={(e) => setGithubRepository(e.target.value)}
                placeholder="dono/repositório (exemplo: usuario/projeto)"
              />
              <small className="form-help-text">
                {translate('applications.repository_format_help')}
              </small>
            </div>

            {tokenError && (
              <div className="error-message">
                <FaExclamationCircle />
                {tokenError}
              </div>
            )}

            <div className="modal-actions">
              <button 
                onClick={() => setShowGithubModal(false)}
                className="cancel-btn"
              >
                {translate('common.cancel')}
              </button>
              <button
                onClick={validateAndSaveToken}
                className="save-btn"
                disabled={!githubToken || !githubRepository || validatingToken}
              >
                {validatingToken ? (
                  <>
                    <span className="loading-spinner"></span>
                    {translate('common.validating')}
                  </>
                ) : (
                  translate('common.save')
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Gráfico de vulnerabilidades */}
      <div className="vulnerability-stats">
        <div className="stats-header">
          <div className="header-left">
            <h2>
              <FaChartBar className="header-icon" />
              Análise de Vulnerabilidades
            </h2>
            <p className="subtitle">Distribuição por nível de severidade</p>
          </div>
          <div className="tabs">
            <button 
              className={activeTab === 'open' ? 'active' : ''} 
              onClick={() => setActiveTab('open')}
            >
              <div className="tab-content">
                <HiShieldExclamation className="tab-icon" />
                <span className="tab-label">Em Aberto</span>
                <span className="total-count">
                  {vulnerabilityStats.open.critical + 
                   vulnerabilityStats.open.high + 
                   vulnerabilityStats.open.moderate + 
                   vulnerabilityStats.open.low}
                </span>
              </div>
            </button>
            <button 
              className={activeTab === 'fixed' ? 'active' : ''} 
              onClick={() => setActiveTab('fixed')}
            >
              <div className="tab-content">
                <HiShieldCheck className="tab-icon" />
                <span className="tab-label">Fechados</span>
                <span className="total-count">
                  {vulnerabilityStats.closed.critical + 
                   vulnerabilityStats.closed.high + 
                   vulnerabilityStats.closed.moderate + 
                   vulnerabilityStats.closed.low}
                </span>
              </div>
            </button>
          </div>
        </div>

        <div className="chart-container">
          <Bar
            data={{
              labels: ['Crítico', 'Alto', 'Moderado', 'Baixo'],
              datasets: [{
                data: activeTab === 'open' 
                  ? [
                      vulnerabilityStats.open.critical,
                      vulnerabilityStats.open.high,
                      vulnerabilityStats.open.moderate,
                      vulnerabilityStats.open.low
                    ]
                  : [
                      vulnerabilityStats.closed.critical,
                      vulnerabilityStats.closed.high,
                      vulnerabilityStats.closed.moderate,
                      vulnerabilityStats.closed.low
                    ],
                backgroundColor: [
                  'rgba(220, 53, 69, 0.85)',
                  'rgba(255, 145, 0, 0.85)',
                  'rgba(255, 193, 7, 0.85)',
                  'rgba(40, 167, 69, 0.85)',
                ],
                borderColor: [
                  'rgb(220, 53, 69)',
                  'rgb(255, 145, 0)',
                  'rgb(255, 193, 7)',
                  'rgb(40, 167, 69)',
                ],
                borderWidth: 0,
                borderRadius: 12,
                barThickness: 40,
                maxBarThickness: 40
              }]
            }}
            options={{
              indexAxis: 'y',
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false
                },
                datalabels: {
                  color: '#2c3e50',
                  anchor: 'end',
                  align: 'end',
                  offset: 8,
                  font: {
                    size: 14,
                    weight: '600',
                    family: "'Inter', sans-serif"
                  },
                  formatter: function(value) {
                    return value;
                  }
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    font: {
                      size: 15,
                      family: "'Inter', sans-serif",
                      weight: '500'
                    },
                    color: '#2c3e50'
                  }
                },
                x: {
                  display: false,
                  ticks: {
                    font: {
                      size: 14,
                      family: "'Inter', sans-serif"
                    },
                    color: '#666'
                  },
                  grid: {
                    display: false
                  }
                }
              },
              layout: {
                padding: {
                  top: 30,
                  right: 60,
                  bottom: 30,
                  left: 30
                }
              }
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApplicationDetails; 