import axios from 'axios';

// Em produção, usa a URL relativa, em desenvolvimento usa localhost
const API_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3000/api';
console.log('Inicializando axios com URL:', API_URL);

const instance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor para adicionar o token em todas as requisições
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Token adicionado ao header:', token.substring(0, 20) + '...');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para tratar erros
instance.interceptors.response.use(
  (response) => {
    console.log('Resposta recebida com sucesso:', {
      url: response.config.url,
      status: response.status,
      statusText: response.statusText
    });
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // Se for erro 401 em rota que não seja de autenticação
      if (!error.config.url.includes('/auth/login')) {
        localStorage.removeItem('token');
        window.location.href = '/auth/signin';
      }
    }
    console.error('Erro na resposta:', error.response || error);
    return Promise.reject(error);
  }
);

export default instance; 