import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaShieldAlt, FaExclamationCircle, FaCheck, FaClock, FaCheckCircle, FaEye, FaExclamationTriangle, FaChartLine, FaHistory, FaFileAlt, FaLightbulb } from 'react-icons/fa';
import axios from '../utils/axios';
import './Monitoring.css';
import Sidebar from './Sidebar';
import Footer from './Footer';
import MonitoringReports from './MonitoringReports';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';

const MonitoringDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [applications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    monitoredApplications: 0,
    preventedAttacks: 0,
    reportsInRetest: 0,
    newReports: {
      total: 0,
      critical: 0,
      high: 0,
      medium: 0,
      low: 0
    },
    inAnalysis: {
      total: 0,
      critical: 0,
      high: 0,
      medium: 0,
      low: 0
    },
    closed: {
      total: 0,
      critical: 0,
      high: 0,
      medium: 0,
      low: 0
    }
  });
  const [activeItem, setActiveItem] = useState('Dashboard');
  const { translate } = useLanguage();
  const [error, setError] = useState(null);

  console.log('Renderizando MonitoringDashboard');
  console.log('Localização atual:', location.pathname);

  const monitoringItems = [
    {
      icon: <FaChartLine />,
      name: translate('monitoring.dashboard'),
      link: '/monitoring/dashboard'
    },
    {
      icon: <FaFileAlt />,
      name: translate('monitoring.reports'),
      link: '/monitoring/reports'
    },
    {
      icon: <FaLightbulb />,
      name: translate('monitoring.insights'),
      link: '/monitoring/insights'
    }
  ];

  useEffect(() => {
    if (location.pathname.includes('/monitoring/reports')) {
      setActiveItem('Relatórios');
    } else if (location.pathname === '/monitoring/dashboard') {
      setActiveItem('Dashboard');
    }
  }, [location]);

  useEffect(() => {
    console.log('useEffect do MonitoringDashboard iniciado');
    const fetchData = async () => {
      try {
        const { isAuthenticated, companyUuid } = checkAuth();

        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        // Buscar estatísticas de monitoramento
        const monitoringResponse = await axios.get(`/monitoring/stats/${companyUuid}`);
        
        // Buscar contagem de aplicações ativas
        const applicationsResponse = await axios.get(`/applications/company/${companyUuid}/active/count`);

        if (monitoringResponse.data.stats) {
          const apiStats = monitoringResponse.data.stats;
          setStats({
            monitoredApplications: applicationsResponse.data.count || 0,
            preventedAttacks: apiStats.totalFixed || 0,
            reportsInRetest: apiStats.retest || 0,
            newReports: {
              total: apiStats.new.total || 0,
              critical: apiStats.new.critical || 0,
              high: apiStats.new.high || 0,
              medium: apiStats.new.medium || 0,
              low: apiStats.new.low || 0
            },
            inAnalysis: {
              total: apiStats.inAnalysis.total || 0,
              critical: apiStats.inAnalysis.critical || 0,
              high: apiStats.inAnalysis.high || 0,
              medium: apiStats.inAnalysis.medium || 0,
              low: apiStats.inAnalysis.low || 0
            },
            closed: {
              total: apiStats.closed.total || 0,
              critical: apiStats.closed.critical || 0,
              high: apiStats.closed.high || 0,
              medium: apiStats.closed.medium || 0,
              low: apiStats.closed.low || 0
            }
          });
        }

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const calculateProgressBar = (stats) => {
    const total = stats.critical + stats.high + stats.medium + stats.low;
    if (total === 0) return [];

    return [
      { width: `${(stats.critical / total) * 100}%`, color: '#dc3545' },
      { width: `${(stats.high / total) * 100}%`, color: '#fd7e14' },
      { width: `${(stats.medium / total) * 100}%`, color: '#ffc107' },
      { width: `${(stats.low / total) * 100}%`, color: '#28a745' }
    ].filter(segment => parseFloat(segment.width) > 0);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'active':
        return <FaCheckCircle style={{ color: '#2E7D32' }} />;
      case 'monitoring':
        return <FaEye style={{ color: '#1565C0' }} />;
      case 'alert':
        return <FaExclamationTriangle style={{ color: '#EF6C00' }} />;
      default:
        return null;
    }
  };

  const cardStats = [
    {
      title: translate('monitoring.newReports'),
      badge: stats.newReports.total.toString(),
      badgeColor: '#FF5722',
      stats: [
        { label: translate('monitoring.critical'), value: stats.newReports.critical, color: '#dc3545', dot: '•' },
        { label: translate('monitoring.high'), value: stats.newReports.high, color: '#fd7e14', dot: '•' },
        { label: translate('monitoring.medium'), value: stats.newReports.medium, color: '#ffc107', dot: '•' },
        { label: translate('monitoring.low'), value: stats.newReports.low, color: '#28a745', dot: '•' }
      ],
      progressBar: calculateProgressBar(stats.newReports)
    },
    {
      title: translate('monitoring.inAnalysis'),
      badge: stats.inAnalysis.total.toString(),
      badgeColor: '#9C27B0',
      stats: [
        { label: translate('monitoring.critical'), value: stats.inAnalysis.critical, color: '#dc3545', dot: '•' },
        { label: translate('monitoring.high'), value: stats.inAnalysis.high, color: '#fd7e14', dot: '•' },
        { label: translate('monitoring.medium'), value: stats.inAnalysis.medium, color: '#ffc107', dot: '•' },
        { label: translate('monitoring.low'), value: stats.inAnalysis.low, color: '#28a745', dot: '•' }
      ],
      progressBar: calculateProgressBar(stats.inAnalysis)
    },
    {
      title: translate('monitoring.closed'),
      badge: stats.closed.total.toString(),
      badgeColor: '#4CAF50',
      stats: [
        { label: translate('monitoring.critical'), value: stats.closed.critical, color: '#dc3545', dot: '•' },
        { label: translate('monitoring.high'), value: stats.closed.high, color: '#fd7e14', dot: '•' },
        { label: translate('monitoring.medium'), value: stats.closed.medium, color: '#ffc107', dot: '•' },
        { label: translate('monitoring.low'), value: stats.closed.low, color: '#28a745', dot: '•' }
      ],
      progressBar: calculateProgressBar(stats.closed)
    }
  ];

  const overallStats = [
    {
      title: translate('monitoring.monitoredApplications'),
      value: stats.monitoredApplications.toString(),
      icon: <FaShieldAlt />,
      color: '#4CAF50'
    },
    {
      title: translate('monitoring.preventedAttacks'),
      value: stats.preventedAttacks.toString(),
      icon: <FaExclamationCircle />,
      color: '#dc3545'
    },
    {
      title: translate('monitoring.reportsInRetest'),
      value: stats.reportsInRetest.toString(),
      icon: <FaClock />,
      color: '#ffc107'
    }
  ];

  return (
    <div className="monitoring-container">
      <Sidebar 
        items={monitoringItems} 
        activeItem={activeItem}
      />
      <div className="content">
        <div className="dashboard-container">
          <h2>Dashboard</h2>
          <div className="overall-stats">
            {overallStats.map((stat, index) => (
              <div key={index} className="overall-stat-card">
                <div className="overall-stat-icon" style={{ color: stat.color }}>
                  {stat.icon}
                </div>
                <div className="overall-stat-info">
                  <span className="overall-stat-value">{stat.value}</span>
                  <span className="overall-stat-title">{stat.title}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="monitoring-stats">
            {cardStats.map((card, index) => (
              <div key={index} className="stat-card">
                <div className="stat-header">
                  <h3>{card.title}</h3>
                  <span className="stat-badge" style={{ backgroundColor: card.badgeColor }}>
                    {card.badge}
                  </span>
                </div>
                <div className="stat-list">
                  {card.stats.map((stat, statIndex) => (
                    <div key={statIndex} className="stat-row">
                      <div className="stat-label">
                        <span className="stat-dot" style={{ color: stat.color }}>{stat.dot}</span>
                        <span>{stat.label}</span>
                      </div>
                      <span className="stat-value" style={{ color: stat.color }}>{stat.value}</span>
                    </div>
                  ))}
                </div>
                <div className="progress-bar">
                  {card.progressBar.map((segment, segIndex) => (
                    <div
                      key={segIndex}
                      className="progress-segment"
                      style={{ 
                        width: segment.width,
                        backgroundColor: segment.color
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="applications-grid">
            {applications.map((app) => (
              <div key={app.id} className="app-card">
                <div className="app-header">
                  <h3>{app.name}</h3>
                  {getStatusIcon(app.status)}
                </div>
                <div className="app-info">
                  <p><strong>Tipo:</strong> {app.type}</p>
                  <p><strong>Última Verificação:</strong> {new Date(app.lastCheck).toLocaleDateString('pt-BR')}</p>
                  <p><strong>Status:</strong> {app.status}</p>
                </div>
                <div className="app-metrics">
                  <div className="metric">
                    <span className="metric-value">{app.vulnerabilities}</span>
                    <span className="metric-label">Vulnerabilidades</span>
                  </div>
                  <div className="metric">
                    <span className="metric-value">{app.criticalIssues}</span>
                    <span className="metric-label">Críticas</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MonitoringDashboard; 