import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './Reports.css';
import { useLanguage } from '../LanguageContext';
import Select from 'react-select';
import { checkAuth } from '../utils/auth';

const Reports = () => {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedImpact, setSelectedImpact] = useState('');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applications, setApplications] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const reportsPerPage = 8;
  const { companyUuid } = useParams();

  const getFaviconUrl = (host) => {
    if (!host) return null;
    return `https://www.google.com/s2/favicons?domain=${host}&sz=32`;
  };

  const formatOptionLabel = ({ value, label, host }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={getFaviconUrl(host)}
        alt=""
        style={{ width: '16px', height: '16px', marginRight: '8px' }}
        onError={(e) => {
          e.target.style.display = 'none';
        }}
      />
      <span>{label}</span>
    </div>
  );

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { isAuthenticated, token, companyUuid } = checkAuth();
        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(`/api/applications?companyUuid=${companyUuid}`);
        const appOptions = response.data.map(app => ({
          value: app.uuid,
          label: app.name,
          host: app.host
        }));
        setApplications(appOptions);
      } catch (error) {
        console.error('Erro ao buscar aplicações:', error);
      }
    };

    fetchApplications();
  }, [navigate]);

  const handleSearch = async () => {
    try {
      const { isAuthenticated, token, companyUuid } = checkAuth();

      if (!isAuthenticated) {
        console.log('Usuário não autenticado');
        navigate('/auth/signin');
        return;
      }

      let queryParams = new URLSearchParams();
      
      if (startDate) {
        queryParams.append('startDate', new Date(startDate).toISOString());
      }
      if (endDate) {
        queryParams.append('endDate', new Date(endDate).toISOString());
      }
      if (selectedImpact) {
        queryParams.append('impact', selectedImpact);
      }
      if (selectedStatus) {
        queryParams.append('status', selectedStatus);
      }
      if (selectedType) {
        queryParams.append('type', selectedType);
      }
      if (selectedApplication) {
        queryParams.append('applicationUuid', selectedApplication.value);
      }

      const response = await axios.get(
        `/reports/company/${companyUuid}?${queryParams.toString()}`
      );
      
      if (response.data.reports && Array.isArray(response.data.reports)) {
        setReports(response.data.reports);
      } else {
        setReports([]);
      }
    } catch (error) {
      console.error('Erro ao buscar relatórios:', error);
      if (error.response?.status === 401) {
        navigate('/auth/signin');
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch, navigate]);

  const handleRowClick = (reportId) => {
    navigate(`/monitoring/reports/${reportId}`);
  };

  const filteredReports = reports.filter(report => {
    if (selectedImpact) {
      const [min, max] = selectedImpact.split('-').map(Number);
      const impact = parseFloat(report.impact);
      if (!(impact >= min && impact <= max)) return false;
    }
    return (
      (selectedType ? report.type === selectedType : true) &&
      (selectedStatus ? report.status === selectedStatus : true)
    );
  });

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);
  const totalPages = Math.ceil(filteredReports.length / reportsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCriticality = (impact) => {
    const numericImpact = parseFloat(impact);
    if (numericImpact >= 9.0 && numericImpact <= 10.0) return "Critical";
    if (numericImpact >= 7.0 && numericImpact < 9.0) return "High";
    if (numericImpact >= 4.0 && numericImpact < 7.0) return "Medium";
    return "Low";
  };

  const getImpactIcon = (impact) => {
    const numericImpact = parseFloat(impact);
    if (numericImpact >= 9.0 && numericImpact <= 10.0) {
      return <FontAwesomeIcon icon={faCircle} style={{ color: '#dc3545' }} />;
    }
    if (numericImpact >= 7.0 && numericImpact < 9.0) {
      return <FontAwesomeIcon icon={faCircle} style={{ color: '#fd7e14' }} />;
    }
    if (numericImpact >= 4.0 && numericImpact < 7.0) {
      return <FontAwesomeIcon icon={faCircle} style={{ color: '#ffc107' }} />;
    }
    return <FontAwesomeIcon icon={faCircle} style={{ color: '#28a745' }} />;
  };

  const impactOptions = [
    { 
      value: '', 
      label: translate('reports.filters.impact_level.all')
    },
    {
      value: '9-10',
      label: translate('reports.filters.impact_level.critical'),
      icon: <FontAwesomeIcon icon={faCircle} style={{ color: '#dc3545' }} />
    },
    {
      value: '7-9',
      label: translate('reports.filters.impact_level.high'),
      icon: <FontAwesomeIcon icon={faCircle} style={{ color: '#fd7e14' }} />
    },
    {
      value: '4-7',
      label: translate('reports.filters.impact_level.medium'),
      icon: <FontAwesomeIcon icon={faCircle} style={{ color: '#ffc107' }} />
    },
    {
      value: '0-4',
      label: translate('reports.filters.impact_level.low'),
      icon: <FontAwesomeIcon icon={faCircle} style={{ color: '#28a745' }} />
    }
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '8px 12px'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    })
  };

  return (
    <div className="monitoring-container">
      <div className="content">
        <div className="reports-container">
          <h2 style={{ textAlign: 'left', marginLeft: '20px' }}>{translate('reports.title')}</h2>
          
          <div className="date-search">
            <label htmlFor="start-date">{translate('reports.filters.date.from')}:</label>
            <input 
              type="date" 
              id="start-date" 
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="end-date">{translate('reports.filters.date.to')}:</label>
            <input 
              type="date" 
              id="end-date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={handleSearch}>{translate('reports.filters.date.search')}</button>
          </div>

          <div className="filters">
            <div className="filter">
              <label htmlFor="impact-select">{translate('reports.filters.impact_level.label')}:</label>
              <Select
                id="impact-select"
                value={impactOptions.find(option => option.value === selectedImpact)}
                onChange={(option) => setSelectedImpact(option.value)}
                options={impactOptions}
                styles={customStyles}
                formatOptionLabel={option => (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {option.icon}
                    <span>{option.label}</span>
                  </div>
                )}
              />
            </div>

            <div className="filter">
              <label htmlFor="status-select">{translate('reports.filters.status.label')}:</label>
              <select
                id="status-select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">{translate('reports.filters.status.all')}</option>
                <option value="New">{translate('reports.filters.status.new')}</option>
                <option value="Under Review">{translate('reports.filters.status.under_review')}</option>
                <option value="Confirmed">{translate('reports.filters.status.confirmed')}</option>
                <option value="Awaiting Fix">{translate('reports.filters.status.awaiting_fix')}</option>
                <option value="In Mitigation">{translate('reports.filters.status.in_mitigation')}</option>
                <option value="Awaiting Retest">{translate('reports.filters.status.awaiting_retest')}</option>
                <option value="Retest Done">{translate('reports.filters.status.retest_done')}</option>
                <option value="Fixed">{translate('reports.filters.status.fixed')}</option>
                <option value="Closed">{translate('reports.filters.status.closed')}</option>
              </select>
            </div>

            <div className="filter">
              <label htmlFor="type-select">{translate('reports.filters.category.label')}:</label>
              <select
                id="type-select"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">{translate('reports.filters.category.all')}</option>
                <option value="Broken Access Control">Broken Access Control</option>
                <option value="Cryptographic Failures">Cryptographic Failures</option>
                <option value="Injection">Injection</option>
                <option value="Cross-Site Scripting (XSS)">Cross-Site Scripting (XSS)</option>
                <option value="Insecure Design">Insecure Design</option>
                <option value="Security Misconfiguration">Security Misconfiguration</option>
                <option value="Vulnerable and Outdated Components">Vulnerable and Outdated Components</option>
                <option value="Identification and Authentication Failures">Identification and Authentication Failures</option>
                <option value="Software and Data Integrity Failures">Software and Data Integrity Failures</option>
                <option value="Security Logging and Monitoring Failures">Security Logging and Monitoring Failures</option>
                <option value="Server-Side Request Forgery (SSRF)">Server-Side Request Forgery (SSRF)</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="filter">
              <label htmlFor="application-select">{translate('reports.filters.application.label')}:</label>
              <Select
                value={selectedApplication}
                onChange={setSelectedApplication}
                options={applications}
                formatOptionLabel={formatOptionLabel}
                isClearable
                placeholder={translate('reports.filters.application.placeholder')}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
          </div>

          <section className="reports-list">
            <table>
              <thead>
                <tr>
                  <th>{translate('reports.table.report_name')}</th>
                  <th>{translate('reports.table.date')}</th>
                  <th>{translate('reports.table.type')}</th>
                  <th>{translate('reports.table.status')}</th>
                  <th>{translate('reports.table.impact')}</th>
                  <th>{translate('reports.table.service')}</th>
                </tr>
              </thead>
              <tbody>
                {currentReports.map((report, index) => (
                  <tr key={index} onClick={() => handleRowClick(report.id)}>
                    <td style={{ textAlign: 'center' }}>{report.name}</td>
                    <td>{new Date(report.createdAt).toLocaleDateString('pt-BR')}</td>
                    <td>{translate(`reports.filters.category.${report.type.toLowerCase().replace(/ /g, '_').replace(/[()]/g, '')}`)}</td>
                    <td>{translate(`reports.filters.status.${report.status.toLowerCase().replace(/ /g, '_')}`)}</td>
                    <td>
                      <div className="impact-cell">
                        {getImpactIcon(report.impact)}
                        <span>{translate(`reports.filters.impact_level.${getCriticality(report.impact).toLowerCase()}`)}</span>
                      </div>
                    </td>
                    <td>{translate(`reports.filters.service.${report.service.toLowerCase()}`)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination">
              <button 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              <span>{currentPage} de {totalPages}</span>
              <button 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Reports;