import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../utils/axios';
import './Login.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    console.log('ResetPassword montado, token:', token);
    
    const checkToken = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        console.log('Verificando token:', token);
        const response = await axios.get(`/auth/verify-reset-token/${token}`);
        console.log('Resposta da verificação:', response.data);
        
        if (response.data.valid) {
          setIsTokenValid(true);
          setEmail(response.data.user.email);
        } else {
          setErrorMessage('Token inválido ou expirado');
        }
      } catch (error) {
        console.error('Erro ao verificar token:', error);
        setErrorMessage(error.response?.data?.error || 'Erro ao verificar token');
      } finally {
        setLoading(false);
      }
    };

    checkToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      if (token) {
        if (newPassword !== confirmPassword) {
          throw new Error('As senhas não coincidem');
        }
        await axios.post('/auth/reset-password', { token, newPassword });
      } else {
        await axios.post('/auth/reset-password', { email });
      }
      navigate('/auth/signin');
    } catch (error) {
      console.error('Erro ao processar solicitação:', error);
      setErrorMessage(
        error.response?.data?.error || 
        error.message || 
        'Erro ao processar solicitação'
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="login-card">
            <img src="/assets/wehack-logo.png" alt="Wehack Logo" className="logo" />
            <h2>Carregando...</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login-page">
      <div className="image-container">
        <img src="/assets/login.jpg" alt="Imagem de Fundo" className="background-image" />
      </div>
      <div className="login-container">
        <div className="login-card">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" className="logo" />
          <h2>{token ? 'Redefinir Senha' : 'Solicitar Redefinição de Senha'}</h2>
          {errorMessage && (
            <div className="error-toast" style={{color: 'red', marginBottom: '1rem'}}>
              {errorMessage}
            </div>
          )}
          <form className="login-form" onSubmit={handleSubmit}>
            {token ? (
              <>
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Nova Senha"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    minLength="6"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Confirmar Nova Senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength="6"
                  />
                </div>
              </>
            ) : (
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            )}
            <button 
              type="submit" 
              className="login-button" 
              disabled={loading}
            >
              {loading ? 'Processando...' : (token ? 'Redefinir Senha' : 'Enviar')}
            </button>
          </form>
          <footer>© 2025 Wehack. Todos os direitos reservados.</footer>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword; 