import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';
import './CompanySelect.css';
import { useLanguage } from '../LanguageContext';
import { FaBuilding, FaCog, FaChevronDown, FaGlobe } from 'react-icons/fa';
import brFlag from '../assets/flags/br.png';
import usFlag from '../assets/flags/us.png';
import ptFlag from '../assets/flags/pt.png';
import gerFlag from '../assets/flags/ger.png';
import esFlag from '../assets/flags/es.png';
import frFlag from '../assets/flags/fr.png';
import itFlag from '../assets/flags/it.png';
import jaFlag from '../assets/flags/ja.png';
import zhFlag from '../assets/flags/zh.png';

const CompanySelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate, currentLanguage, setLanguage } = useLanguage();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const storedCompanies = localStorage.getItem('companies');
    const token = localStorage.getItem('token');

    if (!storedCompanies || !token) {
      console.log('Dados necessários não encontrados, redirecionando para login');
      navigate('/auth/signin');
      return;
    }

    setCompanies(JSON.parse(storedCompanies));
  }, [navigate]);

  const handleCompanySelect = async (companyUuid, companyName) => {
    try {
      const userUuid = localStorage.getItem('userUuid');
      
      const response = await axios.post('/auth/select-company', {
        userUuid,
        companyUuid
      });
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('companyUuid', companyUuid);
        localStorage.setItem('companyName', companyName);

        const event = new Event('companyChanged');
        window.dispatchEvent(event);

        const from = location.state?.from || '/home';
        navigate(from);
      }
    } catch (error) {
      console.error('Erro ao selecionar empresa:', error);
    }
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setIsLanguageDropdownOpen(false);
  };

  const getLanguageFlag = (language) => {
    switch (language) {
      case 'pt-br':
        return brFlag;
      case 'pt-pt':
        return ptFlag;
      case 'en':
        return usFlag;
      case 'de':
        return gerFlag;
      case 'es':
        return esFlag;
      case 'fr':
        return frFlag;
      case 'it':
        return itFlag;
      case 'ja':
        return jaFlag;
      case 'zh':
        return zhFlag;
      default:
        return brFlag;
    }
  };

  return (
    <div className="company-select-page">
      <div className="language-selector">
        <div 
          className="selected-language" 
          onClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)}
        >
          {currentLanguage === 'pt-br' ? (
            <>
              <img src={brFlag} alt="Bandeira do Brasil" />
              <span>Português</span>
            </>
          ) : (
            <>
              <img src={usFlag} alt="US Flag" />
              <span>English</span>
            </>
          )}
        </div>
        {isLanguageDropdownOpen && (
          <div className="language-dropdown">
            <div 
              className="language-option" 
              onClick={() => {
                setLanguage('pt-br');
                setIsLanguageDropdownOpen(false);
              }}
            >
              <img src={brFlag} alt="Bandeira do Brasil" />
              <span>Português</span>
            </div>
            <div 
              className="language-option" 
              onClick={() => {
                setLanguage('en');
                setIsLanguageDropdownOpen(false);
              }}
            >
              <img src={usFlag} alt="US Flag" />
              <span>English</span>
            </div>
          </div>
        )}
      </div>

      <div className="company-select-container">
        <div className="company-select-header">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" />
          <h2>{translate('company_select.title')}</h2>
          <p>{translate('company_select.subtitle')}</p>
        </div>
        
        <div className="companies-list">
          {companies.map((company) => (
            <div
              key={company.uuid}
              className="company-item"
            >
              <div className="company-main" onClick={() => handleCompanySelect(company.uuid, company.name)}>
                <div className="company-icon">
                  <FaBuilding />
                </div>
                <div className="company-info">
                  <h3 className="company-name">{company.name}</h3>
                  <p className="company-id">{translate('company_select.company_id')}: {company.uuid}</p>
                </div>
              </div>
              <button 
                className="manage-account-btn"
                onClick={() => handleCompanySelect(company.uuid, company.name)}
              >
                <FaCog />
                {translate('company_select.manage_account')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanySelect; 