import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaCogs, FaShieldAlt, FaLayerGroup, FaRocket, FaLock, FaBug, FaGlobe, FaMobile, FaServer, FaCode } from 'react-icons/fa';
import { RiComputerLine, RiSmartphoneLine, RiTerminalBoxLine, RiShieldKeyholeFill, RiShieldFlashFill, RiShieldKeyholeLine } from 'react-icons/ri';
import { useLanguage } from '../LanguageContext';
import axios from '../utils/axios';
import { checkAuth } from '../utils/auth';
import Footer from './Footer';
import './Home.css';
import { useModules } from '../hooks/useModules';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeModules } = useModules();
  const { translate } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const response = await axios.get('/auth/users/me', config);
        console.log('Resposta da API:', response.data);
        if (response.data && response.data.name) {
          setUserName(response.data.name.split(' ')[0]);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };
    fetchUserData();
  }, []);

  // Log quando o componente renderiza
  console.log('Renderizando Home com userName:', userName);

  const getApplicationIcon = (type) => {
    switch (type) {
      case 'web application':
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
      case 'mobile application':
        return (
          <div className="icon-container mobile-app">
            <RiSmartphoneLine />
          </div>
        );
      case 'api':
        return (
          <div className="icon-container api-app">
            <RiTerminalBoxLine />
          </div>
        );
      default:
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
    }
  };

  const getFaviconUrl = (host) => {
    try {
      const url = new URL(host);
      return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=64`;
    } catch (error) {
      return `https://www.google.com/s2/favicons?domain=${host}&sz=64`;
    }
  };

  const handleFaviconError = (e) => {
    e.target.style.display = 'none';
  };

  const getOperationIcon = (type) => {
    const getTooltipText = (type) => {
      switch (type) {
        case 'black-box':
          return translate('applications.tooltips.blackBox');
        case 'grey-box':
          return translate('applications.tooltips.greyBox');
        case 'white-box':
          return translate('applications.tooltips.whiteBox');
        default:
          return '';
      }
    };

    switch (type) {
      case 'black-box':
        return (
          <div className="operation-icon-container black-box" title={getTooltipText(type)}>
            <RiShieldKeyholeFill />
          </div>
        );
      case 'grey-box':
        return (
          <div className="operation-icon-container grey-box" title={getTooltipText(type)}>
            <RiShieldFlashFill />
          </div>
        );
      case 'white-box':
        return (
          <div className="operation-icon-container white-box" title={getTooltipText(type)}>
            <RiShieldKeyholeLine />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { isAuthenticated, companyUuid } = checkAuth();

        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(`/applications/company/${companyUuid}`);
        
        console.log('Resposta do backend:', response.data); // Para debug
        
        if (response.data && Array.isArray(response.data)) {
          setApplications(response.data);
        } else if (response.data && Array.isArray(response.data.applications)) {
          setApplications(response.data.applications);
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar aplicações:', error);
        setLoading(false);
      }
    };

    fetchApplications();
  }, [navigate]);

  const getActiveSolutions = () => {
    // Usar a mesma estrutura de soluções da navbar
    const allSolutions = [
      {
        icon: <div className="nav-icon" data-module="applications"><FaCogs /></div>,
        name: translate('navbar.solutions.applications.name'),
        description: translate('navbar.solutions.applications.description'),
        link: '/applications',
        moduleCode: 'applications'
      },
      {
        icon: <div className="nav-icon" data-module="monitoring"><FaShieldAlt /></div>,
        name: translate('navbar.solutions.monitoring.name'),
        description: translate('navbar.solutions.monitoring.description'),
        link: '/monitoring/dashboard',
        moduleCode: 'monitoring'
      },
      {
        icon: <div className="nav-icon" data-module="pentest"><FaLock /></div>,
        name: translate('navbar.solutions.pentest.name'),
        description: translate('navbar.solutions.pentest.description'),
        link: '/pentest',
        moduleCode: 'pentest'
      },
      {
        icon: <div className="nav-icon" data-module="bugbounty"><FaBug /></div>,
        name: translate('navbar.solutions.bugBounty.name'),
        description: translate('navbar.solutions.bugBounty.description'),
        link: '/bugbounty',
        moduleCode: 'bugbounty'
      }
    ];

    // Filtrar apenas as soluções ativas
    return allSolutions.filter(solution => activeModules[solution.moduleCode]);
  };

  const handleAddApplication = () => {
    navigate('/applications');
  };

  if (loading) return <div className="loading">Carregando...</div>;

  const activeCards = getActiveSolutions().filter(card => activeModules[card.moduleCode]);

  return (
    <div className="home-container">
      <h1 className="greeting">Bem-vindo, {userName}!</h1>
      {/* Log inline para debug */}
      {console.log('Valor de userName na renderização:', userName)}
      <h1 className="greeting" style={{ paddingTop: '0px' }}>{translate('home.help')}</h1>
      <div className="widgets-container">
        <div className="widget services">
          <h2>
            <FaLayerGroup />
            {translate('navbar.solutions.title')}
          </h2>
          <div className="services-grid">
            {getActiveSolutions().map((solution, index) => (
              <Link key={index} to={solution.link} className="solution-card">
                <div className="solution-icon" style={{ background: solution.background }}>
                  {solution.icon}
                </div>
                <div className="solution-info">
                  <h3>{solution.name}</h3>
                  <p>{solution.description}</p>
                  <span className="solution-status active">
                    {translate('navbar.solutions.active_solution')}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="widget applications">
          <h2>
            <FaCogs />
            {translate('home.applications')}
          </h2>
          <div className="applications-header">
            <button className="add-app" onClick={handleAddApplication}>
              {translate('home.addApplication')}
            </button>
          </div>
          <div className="applications-table">
            <table>
              <tbody>
                {applications.slice(0, 3).map((app, index) => (
                  <tr 
                    key={index}
                    onClick={() => {
                      console.log('Aplicação clicada:', app); // Para debug
                      if (app && app.id) {
                        navigate(`/applications/${app.id}`);
                      } else {
                        console.error('Aplicação sem ID:', app);
                      }
                    }}
                    style={{ 
                      cursor: 'pointer',
                      transition: 'background-color 0.2s ease',
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--hover-color)'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                  >
                    <td>
                      <div className="application-type">
                        {getApplicationIcon(app.application_type)}
                        <span>{translate(`applications.types.${app.application_type.split(' ')[0].toLowerCase()}`)}</span>
                      </div>
                    </td>
                    <td>
                      <div className="host-container">
                        <img 
                          src={getFaviconUrl(app.host)} 
                          alt="" 
                          className="host-favicon"
                          onError={handleFaviconError}
                        />
                        <span>{app.host}</span>
                      </div>
                    </td>
                    <td>
                      <div className="operation-type">
                        {getOperationIcon(app.operation_type)}
                        <span>{translate(`applications.operations.${app.operation_type.replace('-', '')}`)}</span>
                      </div>
                    </td>
                    <td>
                      <span className={`status ${app.status?.toLowerCase()}`}>
                        {translate(`applications.status.${app.status?.toLowerCase()}`)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {applications.length > 3 && (
              <div style={{ textAlign: 'right', marginTop: '10px', marginRight: '10px' }}>
                <button 
                  onClick={() => navigate('/applications')}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'var(--text-secondary)',
                    cursor: 'pointer',
                    fontSize: '13px',
                    padding: '4px 8px',
                    opacity: 0.8,
                    transition: 'opacity 0.2s ease',
                    textDecoration: 'none'
                  }}
                  onMouseEnter={(e) => e.target.style.opacity = '1'}
                  onMouseLeave={(e) => e.target.style.opacity = '0.8'}
                >
                  {translate('common.view_all')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home; 