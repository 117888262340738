import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import './Login.css';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';
import { useLanguage } from '../LanguageContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTotp, setShowTotp] = useState(false);
  const [totpValues, setTotpValues] = useState(['', '', '', '', '', '']);
  const [token, setToken] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [needsSetup, setNeedsSetup] = useState(false);
  const navigate = useNavigate();
  const { setLanguage, translate } = useLanguage();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post('/auth/login', { email, password });
      const { token, userUuid, role, userName, languagePreference, companies } = response.data;
      
      // Salvar dados básicos
      localStorage.setItem('token', token);
      localStorage.setItem('userUuid', userUuid);
      localStorage.setItem('role', role);
      localStorage.setItem('userName', userName);
      localStorage.setItem('languagePreference', languagePreference);
      
      // Atualizar o idioma no contexto
      if (setLanguage) {
        setLanguage(languagePreference);
      }
      
      // Salvar companies para uso após TOTP
      if (companies) {
        localStorage.setItem('companies', JSON.stringify(companies));
      }

      // Configurar token para as próximas requisições
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Verificar status do TOTP
      const statusResponse = await axios.get('/auth/totp/status');
      
      if (!statusResponse.data.hasTotp) {
        // Se não tiver TOTP configurado, fazer setup
        const setupResponse = await axios.post('/auth/totp/setup');
        setQrCode(setupResponse.data.qrCode);
        setNeedsSetup(true);
      }

      // Mostrar tela de TOTP
      setToken(token);
      setShowTotp(true);
    } catch (error) {
      console.error('Erro no login:', error);
      setErrorMessage(error.response?.data?.error || translate('login.error_generic'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTotpChange = (index, value) => {
    if (value.length > 1) return; // Permite apenas um dígito
    if (!/^\d*$/.test(value)) return; // Permite apenas números

    const newTotpValues = [...totpValues];
    newTotpValues[index] = value;
    setTotpValues(newTotpValues);

    // Move para o próximo input se houver um valor
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name='totp-${index + 1}']`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !totpValues[index] && index > 0) {
      const prevInput = document.querySelector(`input[name='totp-${index - 1}']`);
      if (prevInput) {
        prevInput.focus();
        const newTotpValues = [...totpValues];
        newTotpValues[index - 1] = '';
        setTotpValues(newTotpValues);
      }
    }
  };

  const handleVerifyTotp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const code = totpValues.join('');
      
      if (code.length !== 6) {
        setErrorMessage(translate('totp.invalid_code_length'));
        return;
      }

      const response = await axios.post('/auth/totp/verify', {
        token: code
      });

      if (response.data.success) {
        navigate('/select-company');
      }
    } catch (error) {
      console.error('Erro ao verificar código:', error);
      setErrorMessage(error.response?.data?.error || translate('totp.error_verify'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const detectUserLanguage = async () => {
      try {
        // Verifica se já existe uma preferência salva
        const savedLanguage = localStorage.getItem('languagePreference');
        if (savedLanguage) {
          setLanguage(savedLanguage);
          return;
        }

        // Detecta o idioma baseado no IP
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();
        
        // Define o idioma baseado no país
        let detectedLanguage = 'en'; // Padrão inglês
        if (countryCode === 'br') {
          detectedLanguage = 'pt-br';
        }
        
        // Salva e define o idioma detectado
        localStorage.setItem('languagePreference', detectedLanguage);
        setLanguage(detectedLanguage);
        
        console.log('Idioma detectado:', detectedLanguage);
      } catch (error) {
        console.error('Erro ao detectar idioma:', error);
        // Em caso de erro, usa inglês como padrão
        setLanguage('en');
      }
    };

    detectUserLanguage();
  }, [setLanguage]);

  return (
    <div className="login-page">
      <div className="image-container">
        <img src="/assets/login.jpg" alt="Imagem de Fundo" className="background-image" />
      </div>
      <div className="login-container">
        <div className="login-card">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" className="logo" />
          {!showTotp && <h2>{translate('login.title')}</h2>}
          {errorMessage && <div className="error-toast">{errorMessage}</div>}
          
          {!showTotp ? (
            // Formulário de Login
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  type="email"
                  placeholder={translate('login.email_placeholder')}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {isEmailValid === false && <FaTimesCircle color="red" />}
                {isEmailValid === true && <FaCheckCircle color="green" />}
              </div>
              <div className="input-group">
                <input
                  type="password"
                  placeholder={translate('login.password_placeholder')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="button" className="forgot-password" onClick={() => window.location.href = 'mailto:contact@wehack.com.br?subject=Esqueci%20minha%20senha'}>{translate('login.forgot_password')}</button>
              <button 
                type="submit" 
                className="login-button" 
                disabled={isLoading}
              >
                {isLoading ? 'Aguarde...' : translate('login.submit')}
              </button>
            </form>
          ) : (
            // Formulário TOTP
            <form onSubmit={handleVerifyTotp}>
              <div className="totp-message">
                <h3>
                  {translate('totp.title')}
                  <span className="totp-title-info">
                    <FaInfoCircle />
                    <span className="totp-tooltip">
                      {translate('totp.title_info')}
                    </span>
                  </span>
                </h3>
              </div>
              
              {needsSetup && qrCode && (
                <div className="totp-setup">
                  <div className="qr-code-container">
                    <img src={qrCode} alt="QR Code" />
                    <span className="totp-info">
                      <FaInfoCircle />
                      <span className="totp-tooltip">
                        {translate('totp.description_setup')}
                      </span>
                    </span>
                  </div>
                </div>
              )}

              <div className="totp-inputs-container">
                {totpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    name={`totp-${index}`}
                    value={value}
                    onChange={(e) => handleTotpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={1}
                    pattern="\d"
                    inputMode="numeric"
                    autoComplete="off"
                    required
                  />
                ))}
              </div>
              <button 
                type="submit" 
                className="login-button" 
                disabled={isLoading || totpValues.some(v => !v)}
              >
                {isLoading ? translate('totp.verifying') : translate('totp.verify_button')}
              </button>
            </form>
          )}
          <br></br>
          <p>{translate('login.no_account')} <button type="button" className="contact-us" onClick={() => window.location.href = 'mailto:contact@wehack.com.br?subject=Contato'}>{translate('login.contact_us')}</button></p>
          <footer>© 2025 Wehack. Todos os direitos reservados.</footer>
        </div>
      </div>
    </div>
  );
}

export default Login;
