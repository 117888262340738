import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { FaCogs, FaShieldAlt, FaBug, FaLock, FaUser, FaSignOutAlt, FaTools, FaCode, FaCloud, FaLayerGroup } from 'react-icons/fa';
import './Navbar.css';
import { useLanguage } from '../LanguageContext';
import { useModules } from '../hooks/useModules';
import axios from 'axios';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [companyName, setCompanyName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { translate } = useLanguage();
  const [userRole, setUserRole] = useState(null);
  const { activeModules, setActiveModules } = useModules();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        console.log('Token payload:', payload);
        setUserRole(payload.role);
      } catch (error) {
        console.error('Erro ao decodificar token:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await axios.get('/modules/company');
        const activeModules = response.data.filter(m => m.isActive);
        setActiveModules(activeModules);
      } catch (error) {
        console.error('Erro ao buscar módulos:', error);
      }
    };

    fetchModules();
  }, []);

  // Carregar nome da empresa no mount inicial
  useEffect(() => {
    const storedName = localStorage.getItem('companyName');
    if (storedName) {
      setCompanyName(storedName);
      console.log('Nome inicial da empresa:', storedName);
    }
    fetchCompanyInfo();
  }, []); // Este useEffect roda apenas uma vez no mount

  const fetchCompanyInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const storedName = localStorage.getItem('companyName');
      
      console.log('Verificando informações da empresa:', {
        token: !!token,
        storedName
      });

      if (!token) return;

      // Tentar pegar da API
      try {
        const response = await axios.get('/company/info');
        console.log('Resposta da API:', response.data);

        if (response.data.companyName) {
          setCompanyName(response.data.companyName);
          console.log('Nome da empresa atualizado para:', response.data.companyName);
          return;
        }
      } catch (error) {
        console.error('Erro na API, usando localStorage:', error);
      }

      // Se falhou, usar do localStorage
      if (storedName) {
        setCompanyName(storedName);
        console.log('Usando nome do localStorage:', storedName);
      }
    } catch (error) {
      console.error('Erro ao buscar informações da empresa:', error);
      const storedName = localStorage.getItem('companyName');
      if (storedName) {
        setCompanyName(storedName);
        console.log('Usando nome do localStorage após erro:', storedName);
      }
    }
  };

  // Atualizar quando o componente monta e quando a rota muda
  useEffect(() => {
    console.log('Rota mudou, atualizando informações da empresa');
    fetchCompanyInfo();
  }, [location.pathname]);

  // Atualizar quando o token muda
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' || e.key === 'companyName') {
        console.log('Token ou nome da empresa mudou no storage');
        fetchCompanyInfo();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Atualizar quando a empresa é trocada
  useEffect(() => {
    const handleCompanyChange = () => {
      console.log('Evento companyChanged recebido');
      const storedName = localStorage.getItem('companyName');
      console.log('Nome da empresa no localStorage:', storedName);
      if (storedName) {
        setCompanyName(storedName);
      }
      fetchCompanyInfo();
    };

    window.addEventListener('companyChanged', handleCompanyChange);
    return () => window.removeEventListener('companyChanged', handleCompanyChange);
  }, []);

  const handleLogoClick = () => {
    navigate('/home');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getAllSolutions = () => {
    let solutions = [
      {
        icon: <div className="nav-icon" data-module="applications"><FaCogs /></div>,
        name: translate('navbar.solutions.applications.name'),
        description: translate('navbar.solutions.applications.description'),
        link: '/applications',
        isActive: activeModules.applications,
        moduleCode: 'applications'
      },
      {
        icon: <div className="nav-icon" data-module="monitoring"><FaShieldAlt /></div>,
        name: translate('navbar.solutions.monitoring.name'),
        description: translate('navbar.solutions.monitoring.description'),
        link: '/monitoring/dashboard',
        isActive: activeModules.monitoring,
        moduleCode: 'monitoring'
      },
      {
        icon: <div className="nav-icon" data-module="code_scanning"><FaCode /></div>,
        name: translate('navbar.solutions.codeScanning.name'),
        description: translate('navbar.solutions.codeScanning.description'),
        link: '/code-scanning',
        isActive: activeModules.codeScanning,
        moduleCode: 'code_scanning'
      },
      {
        icon: <div className="nav-icon" data-module="cloud_security"><FaCloud /></div>,
        name: translate('navbar.solutions.cloudSecurity.name'),
        description: translate('navbar.solutions.cloudSecurity.description'),
        link: '/cloud-security',
        isActive: activeModules.cloudSecurity,
        moduleCode: 'cloud_security'
      },
      {
        icon: <div className="nav-icon" data-module="pentest"><FaLock /></div>,
        name: translate('navbar.solutions.pentest.name'),
        description: translate('navbar.solutions.pentest.description'),
        link: '/pentest',
        isActive: activeModules.pentest,
        moduleCode: 'pentest'
      },
      {
        icon: <div className="nav-icon" data-module="bugbounty"><FaBug /></div>,
        name: translate('navbar.solutions.bugBounty.name'),
        description: translate('navbar.solutions.bugBounty.description'),
        link: '/bugbounty',
        isActive: activeModules.bugbounty,
        moduleCode: 'bugbounty'
      }
    ];

    // Adicionar módulo de gerenciamento primeiro se for operator
    if (userRole === 'operator') {
      solutions = [
        {
          icon: <div className="nav-icon" data-module="module_management"><FaTools /></div>,
          name: translate('module_management.title'),
          description: translate('module_management.nav_description'),
          link: '/module-management',
          isActive: true,
          moduleCode: 'module_management'
        },
        ...solutions
      ];
    }

    return solutions;
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/auth/signin');
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img 
          src="/assets/wehack-logo-white.png" 
          alt="Wehack Logo" 
          className="navbar-logo" 
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
        <div className="menu-container" onMouseEnter={() => setMenuOpen(true)} onMouseLeave={() => setMenuOpen(false)}>
          <img 
            src="/assets/menu.png" 
            alt="Menu" 
            className="navbar-menu"
          />
          {menuOpen && (
            <div className="menu-dropdown">
              <h2>
                <FaLayerGroup />
                {translate('navbar.solutions.title')}
              </h2>
              <div className="solutions-grid">
                {getAllSolutions().map((solution, index) => (
                  <Link 
                    key={index} 
                    to={solution.isActive ? solution.link : '#'} 
                    className="solution-card"
                    onClick={e => !solution.isActive && e.preventDefault()}
                  >
                    <div className="solution-icon">{solution.icon}</div>
                    <div className="solution-info">
                      <h3>{solution.name}</h3>
                      <p>{solution.description}</p>
                      <span className={`solution-status ${solution.isActive ? 'active' : 'inactive'}`}>
                        {solution.isActive 
                          ? translate('navbar.solutions.active_solution')
                          : translate('navbar.solutions.not_active')
                        }
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="navbar-right" ref={dropdownRef}>
        <div className="navbar-user" onClick={toggleDropdown}>
          {console.log('Renderizando nome:', { companyName })}
          {companyName || 'Empresa'}
          <span className={`dropdown-icon ${dropdownOpen ? 'open' : ''}`}>▼</span>
        </div>
        {dropdownOpen && (
          <div className="company-dropdown">
            <div className="dropdown-actions">
              <button className="dropdown-action-btn" onClick={() => navigate('/profile')}>
                <FaUser /> {translate('navbar.profile')}
              </button>
              <button className="dropdown-action-btn logout" onClick={handleLogout}>
                <FaSignOutAlt /> {translate('navbar.logout')}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
