import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';
import './Profile.css';
import Footer from './Footer';

const Profile = () => {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { isAuthenticated } = checkAuth();

        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        console.log('Buscando dados do usuário...');
        const response = await axios.get('/auth/users/me');
        console.log('Resposta da API:', response.data);
        
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  if (loading) {
    return (
      <div className="profile-container">
        <div className="loading">{translate('common.loading')}</div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h2>{translate('profile.title')}</h2>
      
      <div className="profile-content">
        {userData && (
          <div className="profile-info">
            <div className="info-group">
              <label>{translate('profile.name')}</label>
              <span>{userData.name || '-'}</span>
            </div>
            
            <div className="info-group">
              <label>{translate('profile.email')}</label>
              <span>{userData.email || '-'}</span>
            </div>
            
            <div className="info-group">
              <label>{translate('profile.company')}</label>
              <span>{userData.company?.companyName || '-'}</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile; 