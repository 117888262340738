import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import { LanguageProvider } from './LanguageContext';
import Reports from './components/Reports';
import MonitoringReports from './components/MonitoringReports';
import MonitoringDashboard from './components/MonitoringDashboard';
import MonitoringInsights from './components/MonitoringInsights';
import Login from './components/Login';
import Home from './components/Home';
import Navbar from './components/Navbar';
import CompanySelect from './components/CompanySelect';
import ReportDetails from './components/ReportDetails';
import Applications from './components/Applications';
import Profile from './components/Profile';
import ResetPassword from './components/ResetPassword';
import PrivateRoute from './utils/PrivateRoute';
import ModuleManagement from './components/ModuleManagement';
import ApplicationDetails from './components/ApplicationDetails';
import TotpSetup from './components/TotpSetup';
// Importe outras páginas conforme necessário

// Configuração do interceptor do Axios
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/auth/signin';
    }
    return Promise.reject(error);
  }
);

function App() {
  return (
    <Router>
      <LanguageProvider>
        <AppContent />
      </LanguageProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavbar = !location.pathname.startsWith('/auth/') && location.pathname !== '/select-company';

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Navigate to="/auth/signin" replace />} />
        <Route path="/auth/signin" element={<Login />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
        <Route path="/select-company" element={<CompanySelect />} />
        <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
        <Route path="/monitoring/reports" element={<PrivateRoute><MonitoringReports /></PrivateRoute>} />
        <Route path="/monitoring/dashboard" element={<PrivateRoute><MonitoringDashboard /></PrivateRoute>} />
        <Route path="/monitoring/insights" element={<PrivateRoute><MonitoringInsights /></PrivateRoute>} />
        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/monitoring/reports/:reportId" element={<PrivateRoute><ReportDetails /></PrivateRoute>} />
        <Route path="/applications" element={<PrivateRoute><Applications /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/module-management" element={<PrivateRoute allowedRoles={['operator']}><ModuleManagement /></PrivateRoute>} />
        <Route path="/applications/:applicationId" element={<PrivateRoute><ApplicationDetails /></PrivateRoute>} />
        <Route path="/auth/totp" element={<TotpSetup />} />
      </Routes>
    </>
  );
}

export default App;
