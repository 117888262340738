import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { useLanguage } from '../LanguageContext';
import { FaShieldAlt, FaMobileAlt, FaQrcode, FaKey, FaLock } from 'react-icons/fa';
import './TotpSetup.css';

const TotpSetup = () => {
  const [qrCode, setQrCode] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const setupTotp = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado');
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get('/auth/totp/setup');
        if (response.data.qrCode) {
          setQrCode(response.data.qrCode);
        }
      } catch (error) {
        console.error('Erro ao gerar QR code:', error);
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        } else {
          setError(translate('totp.error_setup'));
        }
      }
    };

    setupTotp();
  }, [navigate, translate]);

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!totpCode || totpCode.length !== 6) {
        setError(translate('totp.invalid_code_length'));
        setIsLoading(false);
        return;
      }

      const response = await axios.post('/auth/totp/verify', {
        code: totpCode
      });

      if (response.data.success) {
        // Sempre redirecionar para select-company após TOTP bem sucedido
        navigate('/select-company');
      }
    } catch (error) {
      console.error('Erro ao verificar código:', error);
      setError(error.response?.data?.error || translate('totp.error_verifying'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="totp-container">
      <div className="totp-card">
        <div className="totp-header">
          <FaShieldAlt className="shield-icon" />
          <h2>
            <FaLock className="lock-icon" />
            Configuração de Autenticação em Duas Etapas
          </h2>
          <p className="totp-description">
            <FaMobileAlt className="mobile-icon" />
            A autenticação em duas etapas adiciona uma camada extra de segurança à sua conta. 
            Além da senha, você precisará de um código gerado por um aplicativo autenticador.
          </p>
        </div>
        
        {qrCode && (
          <div className="qr-section">
            <div className="qr-header">
              <FaQrcode className="qr-icon" />
              <p>Escaneie o QR Code usando um aplicativo autenticador como Google Authenticator, Microsoft Authenticator ou Authy</p>
            </div>
            <img src={qrCode} alt="QR Code" className="qr-code" />
          </div>
        )}

        <form onSubmit={handleVerifyCode} className="totp-form">
          <div className="form-header">
            <FaKey className="key-icon" />
            <label>Digite o código de 6 dígitos</label>
          </div>
          <div className="form-group">
            <input
              type="text"
              value={totpCode}
              onChange={(e) => setTotpCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
              placeholder="Digite o código"
              maxLength={6}
              pattern="[0-9]*"
              inputMode="numeric"
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button type="submit" className="verify-button" disabled={isLoading}>
            {isLoading ? 'Verificando...' : 'Verificar código'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default TotpSetup; 