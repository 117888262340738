import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { useLanguage } from '../LanguageContext';
import './ModuleManagement.css';

const ModuleManagement = () => {
  const [modules, setModules] = useState([]);
  const { translate } = useLanguage();

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    try {
      const response = await axios.get('/modules/company');
      setModules(response.data);
    } catch (error) {
      console.error('Erro ao buscar módulos:', error);
    }
  };

  const handleToggleModule = async (moduleCode, currentStatus) => {
    try {
      await axios.post('/modules/toggle', {
        moduleCode,
        isActive: !currentStatus
      });
      
      // Recarrega os módulos após a alteração
      fetchModules();
    } catch (error) {
      console.error('Erro ao alterar módulo:', error);
    }
  };

  return (
    <div className="module-management">
      <h2>{translate('module_management.title')}</h2>
      <div className="modules-grid">
        {modules.map(module => (
          <div key={module.code} className="module-card">
            <div className="module-icon" style={{ backgroundColor: module.color }}>
              <i className={`icon-${module.icon}`} />
            </div>
            <div className="module-info">
              <h3>{module.name}</h3>
              <p>{module.description}</p>
            </div>
            <div className="module-actions">
              <button 
                onClick={() => handleToggleModule(module.code, module.isActive)}
                className={`toggle-button ${module.isActive ? 'active' : ''}`}
              >
                {module.isActive ? translate('module_management.deactivate') : translate('module_management.activate')}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModuleManagement; 